import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import axios from "axios";
import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Unstable_Grid2 as Grid,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import ScheduleIcon from "@mui/icons-material/Schedule"; // For appointment schedule
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"; // For completed jobs
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive"; // For urgent tasks
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { convertIsoToLocalFormat } from "../../functions/helperFunctions";

import NotaryCard from "./dashboardComponents/NotaryCard";
import NotaryPerformanceChart from "./charts/NotaryPerformanceChart";
import NotaryJobNotice from "./dashboardComponents/NotaryJobNotice";
import MarkRonAsExpired from "./modals/MarkRonAsExpired";
import DisplayLogs from "./modals/DisplayLogs";

const NotaryDashboard = () => {
  const navigate = useNavigate();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [loadingPage, setLoadingPage] = useState(false);
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state.notarySession.user);
  const notaryJobs = useSelector((state) => state.notarySession.notaryJobs);
  const [year, setYear] = useState(String(new Date().getFullYear()));
  const [years, setYears] = useState([year]);
  const [closestJobDate, setClosestJobDate] = useState("N/A");
  const [secondClosestJobDate, setSecondClosestJobDate] = useState("N/A");
  const [fiveMinBeforeSession, setFiveMinBeforeSession] = useState(false);

  const [infoModal, setInfoModal] = useState({
    voidLoading: false,
    logsOpen: false,
    rescheduleLoading: false,
    rescheduleOpen: false,
    completedLoading: false,
    alertText: "",
    alertOpen: false,
    alertSeverity: "success",
  });

  const [elapsed, setElapsed] = useState({
    hours: 0,
    minutes: 0,
  });

  useEffect(() => {
    if (currentUser?.closest_job && currentUser?.closest_job?.booked_date) {
      const closestIso = currentUser.closest_job.booked_date;
      const userZone = currentUser?.timezone || "America/New_York";

      // Convert the closestIso to the user's local time format
      const dateString = convertIsoToLocalFormat(
        closestIso,
        userZone,
        "cccc, LLLL dd, yyyy hh:mm a"
      );
      setClosestJobDate(dateString);

      // Convert closestIso to a Date object in UTC
      const closestDateUTC = DateTime.fromISO(closestIso, { zone: "utc" });

      const checkTimeDifference = () => {
        // Get the current time in UTC
        const nowUTC = DateTime.utc();

        // Convert both dates to UTC for comparison
        const timeDifference = closestDateUTC - nowUTC;

        // Check if the difference is less than 10 minutes (300,000 milliseconds)
        if (timeDifference <= 30000) {
          setFiveMinBeforeSession(true);
        } else {
          setFiveMinBeforeSession(false);
        }
      };

      // Perform the initial check
      checkTimeDifference();

      const intervalId = setInterval(checkTimeDifference, 30000);

      // Clear the interval when the component unmounts or dependencies change
      return () => clearInterval(intervalId);
    } else {
      setClosestJobDate("N/A");
      setFiveMinBeforeSession(false);
    }
  }, [currentUser]);

  useEffect(() => {
    if (
      currentUser?.second_closest_date &&
      currentUser.second_closest_date !== "N/A"
    ) {
      const secondClosestIso = currentUser.second_closest_date;
      const userZone = currentUser?.timezone || "America/New_York";

      // Convert the secondClosestIso to the user's local time format
      const dateString = convertIsoToLocalFormat(
        secondClosestIso,
        userZone,
        "cccc, LLLL dd, yyyy hh:mm a"
      );

      setSecondClosestJobDate(dateString);
    } else {
      setSecondClosestJobDate("N/A");
    }
  }, [currentUser?.second_closest_date, currentUser?.timezone]);

  useEffect(() => {
    if (!currentUser || !notaryJobs) {
      return;
    }
    if (!currentUser?.role === "notary") {
      navigate("/login");
    }

    if (
      currentUser?.on_site_registered === false ||
      currentUser?.bn_registered === false ||
      currentUser?.availabilities.length === 0
    ) {
      navigate("/notary/finalize");
      return;
    }

    setLoadingPage(false);
  }, [currentUser, notaryJobs]);

  const handleExpireRON = async () => {
    setInfoModal((prev) => ({ ...prev, rescheduleLoading: true }));
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/notary/expire-ron-session`,
        {
          task_id: currentUser?.closest_job?.id,
          bn_id: currentUser?.closest_job?.notarization?.notarization?.bn_id,
          signer: currentUser?.closest_job?.signer,
          currentSession: currentSession,
        },
        {
          withCredentials: true,
        }
      );
      if (resp.data.success === true) {
        setInfoModal((prev) => ({
          ...prev,
          alertText: resp.data.message,
          alertOpen: true,
          alertSeverity: "success",
        }));
      }
    } catch (e) {
      setInfoModal((prev) => ({
        ...prev,
        alertText: e.response.data.message,
        alertOpen: true,
        alertSeverity: "error",
      }));
    } finally {
      setInfoModal((prev) => ({
        ...prev,
        rescheduleLoading: false,
        rescheduleOpen: false,
      }));
    }
  };

  //! /////////////////////////////////////////////////////////////////
  const handleSetCompleted = async () => {
    setInfoModal((prev) => ({ ...prev, completedLoading: true }));
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/notary/set-completed-notarization`,
        {
          task_id: currentUser?.closest_job?.id,
          bn_id: currentUser?.closest_job?.notarization?.notarization?.bn_id,
          currentSession: currentSession,
        },
        {
          withCredentials: true,
        }
      );
      if (resp.data.success === true) {
      }
    } catch (e) {
      setInfoModal((prev) => ({
        ...prev,
        alertText: e.response.data.message,
        alertOpen: true,
        alertSeverity: "error",
      }));
    } finally {
      setInfoModal((prev) => ({ ...prev, completedLoading: false }));
    }
  };

  const snackHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setInfoModal((prev) => ({ ...prev, alertOpen: false, alertText: "" }));
  };

  useEffect(() => {
    if (currentUser?.activity) {
      const yearsSet = new Set();

      currentUser?.activity.forEach((item) => {
        yearsSet.add(item.year);
      });

      setYears([...yearsSet].sort((a, b) => a - b));
    }
  }, []);

  const handleChartValues = () => {
    let completedJobs = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let acceptedJobs = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let cancelledJobs = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    currentUser?.activity.map((item) => {
      if (item?.year === year) {
        completedJobs[item.month] = item.completed;
        acceptedJobs[item.month] = item.accepted;
        cancelledJobs[item.month] = item.cancelled;
      }
      return null;
    });

    return [
      {
        name: "Completed",
        type: "column",
        data: completedJobs,
      },
      {
        name: "Total Accepted",
        type: "area",
        data: acceptedJobs,
      },
      {
        name: "Expired",
        type: "line",
        data: cancelledJobs,
      },
    ];
  };

  return (
    <>
      {loadingPage ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 1,
              my: 2,
              backgroundColor: "#EEF2F6",
            }}
          >
            <Container maxWidth="xl">
              <Grid container spacing={2} justifyContent="center">
                {/* <Grid xs={12} sm={6} md={2}>
                  <NotaryCard
                    title="Current Requests"
                    value={notaryJobs?.jobs_list?.length}
                    icon={NotificationsActiveIcon}
                    color="primary.main"
                    tooltip="Requests awaiting your action"
                  />
                </Grid> */}
                <Grid xs={12} sm={6} md={2}>
                  <NotaryCard
                    title="Completed"
                    value={currentUser?.completed_jobs}
                    icon={CheckCircleOutlineIcon}
                    color="success.main"
                    tooltip="Jobs successfully completed"
                  />
                </Grid>
                <Grid xs={12} sm={6} md={2}>
                  <NotaryCard
                    title="Pending"
                    value={currentUser?.pending_jobs}
                    icon={TaskAltIcon}
                    color="warning.main"
                    tooltip="Your Pending Jobs"
                  />
                </Grid>
                <Grid xs={12} sm={6} md={2}>
                  <NotaryCard
                    title="Expired"
                    value={currentUser?.cancelled_jobs}
                    icon={CancelIcon}
                    color="error.main"
                    tooltip="Time for your next notarization"
                  />
                </Grid>
                <Grid xs={12} sm={6} md={5}>
                  <NotaryCard
                    title="Upcoming Notarization"
                    value={secondClosestJobDate}
                    icon={ScheduleIcon}
                    color="info.main"
                    tooltip="Time for your upcoming notarization"
                    isUpcoming={true}
                  />
                </Grid>
                <Grid xs={12}>
                  <NotaryJobNotice
                    currentUser={currentUser}
                    handleSetCompleted={handleSetCompleted}
                    infoModal={infoModal}
                    setInfoModal={setInfoModal}
                    closestJobDate={closestJobDate}
                    fiveMinBeforeSession={fiveMinBeforeSession}
                    elapsed={elapsed}
                    setElapsed={setElapsed}
                  />
                </Grid>
                <Grid xs={12}>
                  <NotaryPerformanceChart
                    year={year}
                    setYear={setYear}
                    years={years}
                    handleChartValues={handleChartValues}
                    chartSeries={handleChartValues()}
                    sx={{ height: "100%" }}
                  />
                </Grid>
              </Grid>
              <Snackbar
                open={infoModal.alertOpen}
                autoHideDuration={10000}
                onClose={snackHandleClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                sx={{ mt: "50px", ml: lgUp ? "150px" : undefined }}
              >
                <Alert
                  onClose={snackHandleClose}
                  severity={infoModal.alertSeverity}
                  sx={{
                    width: "100%",
                    backgroundColor:
                      infoModal.alertSeverity === "success"
                        ? "#388e3c" // Green shade for success
                        : "#f04438", // Red shade for error
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                  iconMapping={{
                    success: <CheckCircleIcon sx={{ color: "#fff" }} />, // Changed to success icon
                    error: <CancelIcon sx={{ color: "#fff" }} />, // Changed to error icon
                  }}
                >
                  {infoModal.alertText}
                </Alert>
              </Snackbar>
            </Container>
          </Box>
          <MarkRonAsExpired
            infoModal={infoModal}
            setInfoModal={setInfoModal}
            elapsed={elapsed}
            handleExpireRON={handleExpireRON}
          />
          <DisplayLogs infoModal={infoModal} setInfoModal={setInfoModal} />
        </>
      )}
    </>
  );
};

export default NotaryDashboard;
