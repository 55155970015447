import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { useSignIn, useClerk } from "@clerk/clerk-react";
import { loginUser } from "../../store/actions/userActions";
import ReCAPTCHA from "react-google-recaptcha";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  updateSession,
  setExpiredSession,
} from "../../store/actions/sessionsActions";
import NotificationCard from "../popover/NotificationCard";
import {
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Stack,
  Link,
  TextField,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  CircularProgress,
  IconButton,
  InputAdornment,
  Modal,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { Visibility, VisibilityOffOutlined } from "@mui/icons-material";

const LoginComponent = () => {
  // const { signIn } = useSignIn();
  // const { signOut } = useClerk();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [defEmail, setDefEmail] = useState("");
  const [defPwd, setDefPwd] = useState("");
  const [remember, setRemember] = useState(false);
  const expiredSession = useSelector((state) => state.sessions.expiredSession);
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const miniSize = useMediaQuery("(max-width:330px)");
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [loginDisabled, setLoginDisabled] = useState(false);
  const [count, setCount] = useState(0);
  const [recaptchaKey, setRecaptchaKey] = useState(Date.now());
  const [captchaModal, setCaptchaModal] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberInStorage, setRememberInStorage] = useState(false);
  const [navigateLoading, setNavigateLoading] = useState(false);
  const handleRecaptchaVerify = async (response) => {
    setRecaptchaValue(response);
    setRecaptchaVerified(true);
  };
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    if (expiredSession && !openAlert) {
      setOpenAlert(true);
    }
  }, [expiredSession]);

  const handleRemember = (e, formik) => {
    const existingData = localStorage.getItem("ujsffq");

    if (existingData && remember) {
      localStorage.removeItem("ujsffq");
      formik.setFieldValue("email", "");
      formik.setFieldValue("password", "");
      setDefEmail("");
      setDefPwd("");
      setRememberInStorage(false);
    }
    setRemember(e.target.checked);
  };

  const resetRecaptcha = () => {
    setRecaptchaKey(Date.now());
    setRecaptchaValue("");
    setRecaptchaVerified(false);
    if (count > 1) {
      setLoginDisabled(true);
    }
  };

  useEffect(() => {
    const isRecaptcha = localStorage.getItem("recaptcha") === "true";
    const RecaptchaAttempt = localStorage.getItem("failedAttempts") === "2";
    if (count > 1 || isRecaptcha || RecaptchaAttempt) {
      if (captchaModal === false) {
        setCaptchaModal(true);
        localStorage.setItem("recaptcha", "true");
        localStorage.setItem("failedAttempts", "2");
      }
      if (recaptchaVerified) {
        const interval = setInterval(() => {
          localStorage.removeItem("recaptcha");
          localStorage.removeItem("failedAttempts");
          setCount(0);
          setCaptchaModal(false);
        }, 2000);

        return () => clearInterval(interval);
      }
    }
  }, [count, mobileSize, recaptchaVerified]);

  const signInButtonStyle = mobileSize
    ? {
        width: "15%",
        backgroundColor: "#F8FAFC",
        mb: 1,
        p: "6px",
      }
    : {
        width: "145px",
        backgroundColor: "#F8FAFC",
        py: "5px",
      };

  useEffect(() => {
    const getItemFromLocalStorage = (key) => {
      const item = localStorage.getItem(key);
      return JSON.parse(item);
    };

    const userData = getItemFromLocalStorage("ujsffq");

    if (userData) {
      setDefEmail(userData.email);
      setDefPwd("000000");
      setRemember(true);
      setRememberInStorage(true);
    }
  }, []);

  useEffect(() => {
    const isRecaptcha = localStorage.getItem("recaptcha") === "true";
    if (count > 1 || isRecaptcha) {
      if (recaptchaVerified === false) {
        setLoginDisabled(true);
      } else {
        setLoginDisabled(false);
      }
    }
  }, [recaptchaVerified, count]);

  useEffect(() => {
    if (
      error !== "" &&
      error !== "We are updating our application. Please come back later"
    ) {
      const timer = setTimeout(() => {
        setError("");
      }, 6000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [error]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: defEmail || "",
      password: defPwd || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Email address must be a valid email")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values, helpers) => {
      if (count > 1) {
        if (recaptchaVerified === false) {
          return;
        }
        // const is_verified = await handleVerifyRecaptcha();

        // if (is_verified.data.success === false) {
        //   return;
        // }
      }
      try {
        setLoading(true);
        const { email, password } = values;
        const result = await dispatch(
          loginUser(
            email,
            password,
            remember,
            recaptchaValue,
            count,
            defEmail,
            rememberInStorage
          )
        );
        if (result?.success === false) {
          resetRecaptcha();
          setCount(count + 1);
          const RecaptchaAttempt = localStorage.getItem("failedAttempts");

          if (!RecaptchaAttempt) {
            localStorage.setItem("failedAttempts", "1");
          } else if (RecaptchaAttempt === "1") {
            localStorage.setItem("failedAttempts", "2");
          }
          setRemember(false);
          if (typeof localStorage !== "undefined") {
            // Remove the item with the name "ujsffq"
            localStorage.removeItem("ujsffq");
          }
          formik.setFieldValue("email", "");
          formik.setFieldValue("password", "");
          formik.setTouched({});
          setError(result?.message);
          setLoading(false);
          return;
        }
        if (result && result?.data?.message === "normal") {
          let existingData = localStorage.getItem("ujsffq");

          // Parse the existing data as JSON or create an empty object if it doesn't exist
          let existingObject = existingData ? JSON.parse(existingData) : {};

          // Update the existing object with the new email value
          existingObject.email = email;

          // Set the updated object in localStorage
          localStorage.setItem("ujsffq", JSON.stringify(existingObject));

          // if (result.data.first) {
          //   navigate("/selectcounties");
          // } else {
          // await dispatch(
          //   connectWebsocket(result?.data?.sessionType, result?.data?.token)
          // );

          await dispatch(updateSession(result?.data?.data));

          if (result?.data?.data?.sessionType === "notarySession") {
            navigate("/notary/dashboard");
          } else if (
            result?.data?.data?.sessionType === "userSession" ||
            result?.data?.data?.sessionType === "subUserSession" ||
            result?.data?.data?.sessionType === "adminSession"
          ) {
            navigate("/dashboard");
          }
        } else if (result && result?.data?.message === "checkout") {
          window.location.href = result?.data?.data;
        } else if (result && result?.data?.message === "faunset") {
          const token = result?.data?.data;
          navigate(`/2fa-secure?token=${token}`);
          return;
        } else if (result && result?.data?.message === "emailFa") {
          const token = result?.data?.data;
          navigate(`/2fa-secure/email?token=${token}&remember=${remember}`);
          return;
        } else if (result && result?.data?.message === "totp") {
          const token = result?.data?.data;
          navigate(`/2fa-secure/totp?token=${token}&remember=${remember}`);
          return;
        } else if (result && result?.data?.message === "smsFa") {
          const token = result?.data?.data;
          navigate(`/2fa-secure/sms?token=${token}&remember=${remember}`);
          return;
        }
      } catch (err) {
        setLoading(false);
        setError(err.response.data.message);
        setCount(count + 1);
        resetRecaptcha();
      }
    },
  });

  const signInWith = async (strategy) => {
    try {
      // await signIn.authenticateWithRedirect({
      //   strategy,
      //   redirectUrl: "/login",
      //   redirectUrlComplete: "/sso_redirect_1saf32mf23mnk23f23",
      // });
    } catch (e) {}
  };

  return (
    <>
      {loadingPage ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={
              mobileSize
                ? {
                    justifyContent: "center",
                    overflow: "auto",

                    minHeight: "100vh",
                    overflowY: "hidden",
                    display: "flex",
                    mx: 1,
                    flexDirection: "column",
                  }
                : {
                    backgroundImage: "url('/img/website_img/homeImg.jpeg')",
                    backgroundRepeat: "repeat",

                    minHeight: "100vh",
                    overflow: "hidden",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }
            }
          >
            <NotificationCard open={openAlert} setOpen={setOpenAlert} />
            <Box
              sx={
                mobileSize
                  ? {
                      backgroundColor: "#fff",
                      py: "1rem",
                      width: "100%",
                      flexGrow: 1,
                    }
                  : {
                      backgroundColor: "#fff",
                      mt: 2,
                      maxWidth: 700,
                      borderRadius: "25px",
                      borderBottomLeftRadius: "0px",
                      borderBottomRightRadius: "0px",
                      px: 3,
                      py: "10px",
                      width: "100%",
                      pt: "2rem",
                    }
              }
            >
              {/* <div
                style={
                  mobileSize
                    ? { width: "100%", padding: "1rem" }
                    : { padding: "1rem" }
                }
              > */}
              <Stack spacing={1} sx={{ mb: 3 }}>
                <Box
                  sx={{ cursor: "pointer" }}
                  display="flex"
                  justifyContent="center"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img
                    style={{
                      width: miniSize ? "8rem" : "9rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "-9px",
                    }}
                    alt="Permit Rockstar Logo"
                    src="/img/website_img/PermitRockstar.png"
                  />
                </Box>

                <Typography
                  sx={{ textAlign: "center" }}
                  color="#004976"
                  variant="h6"
                >
                  Hi, Welcome Back
                </Typography>
                <Typography
                  sx={{ textAlign: "center", lineHeight: "3px", pb: "1rem" }}
                  color="text.secondary"
                  variant="body2"
                >
                  Enter your credentials to continue
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    pt: 1,
                    fontWeight: "normal",
                    fontSize: "1rem",
                  }}
                  color="#101820"
                  variant="h6"
                >
                  {/* Sign in with */}
                </Typography>

                <Stack
                  justifyContent="center"
                  direction={mobileSize ? "row" : "row"}
                  spacing={mobileSize ? 2 : 5}
                  sx={{ mx: "auto" }}
                >
                  {/* <Grid item xs={12} sm={4}> */}
                  <Button
                    disabled={true}
                    size="large"
                    sx={signInButtonStyle}
                    variant="outlined"
                    onClick={() => signInWith("oauth_google")}
                  >
                    <img
                      alt="google-sso"
                      src="/img/logos/google_logo.png"
                      style={{
                        width: "25px",
                        marginRight: mobileSize ? "0px" : "8px",
                      }}
                    />
                    {mobileSize ? "" : "Google"}
                  </Button>
                  {/* </Grid> */}
                  {/* <Grid item xs={12} sm={4}> */}
                  <Button
                    disabled={true}
                    size="large"
                    sx={signInButtonStyle}
                    variant="outlined"
                    onClick={() => signInWith("oauth_facebook")}
                  >
                    <img
                      alt="facebook-sso"
                      src="/img/logos/facebook_logo.png"
                      style={{
                        width: "25px",
                        marginRight: mobileSize ? "0px" : "8px",
                      }}
                    />
                    {mobileSize ? "" : "Facebook"}
                  </Button>
                  {/* </Grid> */}
                  {/* <Grid item xs={12} sm={4}> */}
                  <Button
                    disabled={true}
                    size="large"
                    sx={signInButtonStyle}
                    variant="outlined"
                    onClick={() => signInWith("oauth_apple")}
                  >
                    <img
                      alt="apple-sso"
                      src="/img/logos/apple_logo.png"
                      style={{ width: "35px" }}
                    />
                    {mobileSize ? "" : "Apple"}
                  </Button>
                  {/* </Grid> */}
                </Stack>
                <Typography
                  sx={{
                    textAlign: "center",
                    pt: 0.5,
                    fontWeight: "bold",
                  }}
                  variant="body2"
                  color="primary"
                >
                  {/* Sign in with */}
                  Social logins are temporarily unavailable. Please try again
                  later.
                </Typography>
                <Box
                  sx={{
                    flex: "1 1 auto",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    pt: "5px",
                  }}
                >
                  <div
                    style={{
                      border: "1px solid #E7E5E5",
                      width: "100%",
                      marginRight: "2rem",
                    }}
                  />
                  <Button
                    disabled
                    size="large"
                    sx={{
                      width: "145px",
                      backgroundColor: "#F8FAFC",
                      py: "2px",
                      borderRadius: "8px",
                      mb: "10px",
                      mt: "8px",
                    }}
                    variant="outlined"
                    // onClick={() => {
                    //   signOut();
                    // }}
                  >
                    OR
                  </Button>
                  <div
                    style={{
                      border: "1px solid #E7E5E5",
                      width: "100%",
                      marginLeft: "2rem",
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "normal",
                    fontSize: "1rem",
                  }}
                  color="#101820"
                  variant="h6"
                >
                  Sign in with Email Address
                </Typography>
              </Stack>
            </Box>
            <Box
              sx={
                !mobileSize
                  ? {
                      backgroundColor: "#fff",
                      mb: 2,
                      maxWidth: 700,
                      borderRadius: "25px",
                      borderTopLeftRadius: "0px",
                      borderTopRightRadius: "0px",
                      px: 3,
                      py: "10px",
                      width: "100%",
                      pt: "2rem",
                    }
                  : { mb: 3, backgroundColor: "#fff" }
              }
            >
              <form
                noValidate
                autoComplete="off"
                onSubmit={formik.handleSubmit}
                style={
                  tabletSize && !mobileSize
                    ? { paddingLeft: "0.5rem", paddingRight: "0.5rem" }
                    : {}
                }
              >
                <Stack spacing={2} sx={{ px: mobileSize ? 0.2 : 0 }}>
                  <TextField
                    error={!!(formik.touched.email && formik.errors.email)}
                    disabled={remember && rememberInStorage}
                    fullWidth
                    helperText={formik.touched.email && formik.errors.email}
                    label="Email Address"
                    name="email"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.handleChange(e); // Trigger Formik's handleChange as well
                    }}
                    type="email"
                    value={formik.values.email}
                  />
                  <TextField
                    error={
                      !!(formik.touched.password && formik.errors.password)
                    }
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    disabled={remember && rememberInStorage}
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    label="Password"
                    name="password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={remember && rememberInStorage}
                            onClick={() => {
                              if (remember) {
                                return;
                              } else {
                                setShowPassword(!showPassword);
                              }
                            }}
                            onMouseDown={(e) => e.preventDefault()} // Prevent focus change on mouse down
                            onMouseUp={(e) => e.preventDefault()} // Prevent focus change on mouse up
                            edge="end"
                          >
                            {showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOffOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={6}>
                    <Box>
                      <FormControlLabel
                        sx={{ mt: "5px", mr: "auto" }}
                        control={
                          <Checkbox
                            checked={remember}
                            onChange={(e) => handleRemember(e, formik)}
                          />
                        }
                        label={
                          <Typography
                            sx={{ textAlign: "center", lineHeight: "3px" }}
                            color="text.secondary"
                            variant="body2"
                          >
                            Remember Me
                          </Typography>
                        }
                        size="sm"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Link
                        // component={NextLink}
                        sx={{
                          fontWeight: "bold",
                          ml: "auto",
                          mt: 1,
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          navigate("/resetpassword");
                        }}
                        underline="hover"
                        variant="subtitle2"
                      >
                        Forgot password?
                      </Link>
                    </Box>
                  </Grid>
                </Grid>

                {formik.errors.submit && (
                  <Typography color="error" sx={{ mt: 3 }} variant="body2">
                    {formik.errors.submit}
                  </Typography>
                )}
                <Typography
                  variant="body1"
                  sx={{ minHeight: "1.5rem" }}
                  textAlign="center"
                  my={1}
                  color="error"
                  fontWeight="bold"
                >
                  {error}
                </Typography>

                <LoadingButton
                  disabled={loginDisabled}
                  loading={loading}
                  fullWidth
                  size="large"
                  sx={{ mt: 0, mb: mobileSize ? 1 : 0 }}
                  type="submit"
                  variant="contained"
                >
                  Sign In
                </LoadingButton>
              </form>
              {!mobileSize && (
                <div
                  style={{
                    border: "1px solid #E7E5E5",
                    width: "100%",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                />
              )}

              <Box
                sx={{
                  flex: "1 1 auto",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  px: tabletSize && !mobileSize ? "0.5rem" : undefined,
                }}
              >
                <LoadingButton
                  loading={navigateLoading}
                  onClick={(e) => {
                    setNavigateLoading(true);
                    window.location.href =
                      "https://permitrockstar.com/pricing/";
                    // navigate("/register");
                  }}
                  color="warning"
                  fullWidth
                  size="large"
                  sx={{
                    // backgroundColor: "##CDAF0B",

                    ":hover": { backgroundColor: "#CDAF0B" },
                  }}
                  variant="contained"
                >
                  Don't have an account? Register now for free
                </LoadingButton>

                <Link
                  // component={NextLink}
                  sx={{
                    fontWeight: "bold",
                    // ml: "auto",
                    mt: 2,
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    navigate("/");
                  }}
                  underline="hover"
                  variant="subtitle2"
                >
                  Back to Home
                </Link>
              </Box>
              {/* </div> */}
            </Box>
          </Box>
          <Modal open={captchaModal}>
            <Box
              sx={{
                position: "absolute",
                top: "0%",
                left: "0%",
                width: "100%",
                height: "100%",
                overflow: "auto", // Allows scrolling if the content exceeds the viewport
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
                display: "flex",
                alignItems: "center", // Center content vertically
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "4px",
                }}
              >
                <Typography variant="h5">
                  Please Verify You Are Human
                </Typography>
                <Box mt={2}>
                  <ReCAPTCHA
                    key={recaptchaKey}
                    sitekey={process.env.REACT_APP_RECAPTCHA}
                    onChange={handleRecaptchaVerify}
                  />
                </Box>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

export default LoginComponent;
