import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import CurrentPlan from "../dashboard/CurrentPlan";
import TotalNoc from "../dashboard/TotalNoc";
import TotalCounties from "../dashboard/TotalCounties";
import NewNoc from "../dashboard/NewNoc";
import AvailableNoc from "../dashboard/AvailableNoc";
import NocUsage from "../dashboard/NocUsage";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MobileFooter from "../layout/MobileFooter";
import LottieAnimation from "../../animation/LottieAnimation";
import PpUsage from "../dashboard/PpUsage";

const Dashboard = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const currentSession = useSelector((state) => state.sessions.currentSession);

  const currentUser = useSelector((state) => state[currentSession].user);

  const [nocYear, setNocYear] = useState(String(new Date().getFullYear()));
  const [nocYears, setNocYears] = useState([nocYear]);
  const [ppYear, setPPYear] = useState(String(new Date().getFullYear()));
  const [ppYears, setPPYears] = useState([ppYear]);

  const [loading, setLoading] = useState(false);
  const [paymentCards, setPaymentCards] = useState([]);
  const [startPeriod, setStartPeriod] = useState(null);
  const [endPeriod, setEndPeriod] = useState(null);
  const [timezone, setTimezone] = useState("");

  //? //////////////////////////////////////////////////////////////////////////
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [totalNocTitle, setTotalNocTitle] = useState(false);
  //? //////////////////////////////////////////////////////////////////////////
  const [availableCountyTitle, setAvailableCountyTitle] = useState(false);
  const [isCountyFadingOut, setIsCountyFadingOut] = useState(false);
  //? //////////////////////////// NOC //////////////////////////////////////////////
  const [nocUsageTitle, setNocUsageTitle] = useState(false);
  const [isNocUsageFadingOut, setIsNocUsageFadingOut] = useState(false);
  //? ////////////////////////// PP /////////////////////////////////////////////
  const [ppUsageTitle, setPPUsageTitle] = useState(false);
  const [isPPUsageFadingOut, setIsPPUsageFadingOut] = useState(false);
  //? //////////////////////////////////////////////////////////////////////////
  const [createdTitle, setCreatedTitle] = useState(false);
  const [isCreatedFadingOut, setIsCreatedFadingOut] = useState(false);
  //? //////////////////////////////////////////////////////////////////////////
  const [availableTitle, setAvailableTitle] = useState(false);
  const [isAvailableFadingOut, setIsAvailableFadingOut] = useState(false);
  //? //////////////////////////////////////////////////////////////////////////
  const [newNocTitle, setNewNocTitle] = useState(false);
  const [isNewNocFadingOut, setIsNewNocFadingOut] = useState(false);

  const between905and1125 = useMediaQuery(
    "(min-width:900px) and (max-width:1125px)"
  );
  const between1200and1405 = useMediaQuery(
    "(min-width:1200px) and (max-width:1405px)"
  );
  const between1400and1700 = useMediaQuery(
    "(min-width:1400px) and (max-width:1700px)"
  );
  const miniSize = useMediaQuery("(max-width:330px)");
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const largePC = useMediaQuery(theme.breakpoints.up("xl"));
  const [openWelcome, setOpenWelcome] = useState(false);

  useEffect(() => {
    if (currentSession === "userSession") {
      if (currentUser?.user?.firstLogin) {
        setOpenWelcome(true);
      }
    } else if (currentSession === "subUserSession") {
      if (currentUser?.subuser?.firstLogin) {
        setOpenWelcome(true);
      }
    }
  }, []);

  useEffect(() => {
    if (currentUser?.user?.expired === true) {
      navigate("/subscription");
    }
  }, [currentUser]);

  useEffect(() => {
    if (totalNocTitle) {
      const timeout = setTimeout(() => {
        setIsFadingOut(true);
        const fadeOutTimeout = setTimeout(() => {
          setTotalNocTitle(false);
          setIsFadingOut(false);
        }, 1000);

        return () => clearTimeout(fadeOutTimeout);
      }, 3500);

      return () => clearTimeout(timeout);
      //? //////////////////////////////////////////////////////////////////////////
    } else if (availableCountyTitle) {
      // First timeout to start the fade-out effect after 4500ms
      const availableCountyTimeout = setTimeout(() => {
        setIsCountyFadingOut(true);
        const countyFadeOutTimeout = setTimeout(() => {
          setAvailableCountyTitle(false);
          setIsCountyFadingOut(false);
        }, 1000);

        return () => clearTimeout(countyFadeOutTimeout);
      }, 3500);

      return () => clearTimeout(availableCountyTimeout);
      //? //////////////////////////////////////////////////////////////////////////
    } else if (nocUsageTitle) {
      const nocUsageTimeout = setTimeout(() => {
        setIsNocUsageFadingOut(true);
        const nocUsageFadeOutTimeout = setTimeout(() => {
          setNocUsageTitle(false);
          setIsNocUsageFadingOut(false);
        }, 1000);

        return () => clearTimeout(nocUsageFadeOutTimeout);
      }, 3500);

      return () => clearTimeout(nocUsageTimeout);
      //? //////////////////////////////////////////////////////////////////////////
    } else if (ppUsageTitle) {
      const ppUsageTimeout = setTimeout(() => {
        setIsPPUsageFadingOut(true);
        const ppUsageFadeOutTimeout = setTimeout(() => {
          setPPUsageTitle(false);
          setIsPPUsageFadingOut(false);
        }, 1000);

        return () => clearTimeout(ppUsageFadeOutTimeout);
      }, 3500);

      return () => clearTimeout(ppUsageTimeout);
      //? //////////////////////////////////////////////////////////////////////////
    } else if (createdTitle) {
      const createdTimeout = setTimeout(() => {
        setIsCreatedFadingOut(true);
        const createdFadeOutTimeout = setTimeout(() => {
          setCreatedTitle(false);
          setIsCreatedFadingOut(false);
        }, 1000);

        return () => clearTimeout(createdFadeOutTimeout);
      }, 3500);

      return () => clearTimeout(createdTimeout);
      //? //////////////////////////////////////////////////////////////////////////
    } else if (availableTitle) {
      const availableTimeout = setTimeout(() => {
        setIsAvailableFadingOut(true);
        const availableFadeOutTimeout = setTimeout(() => {
          setAvailableTitle(false);
          setIsAvailableFadingOut(false);
        }, 1000);

        return () => clearTimeout(availableFadeOutTimeout);
      }, 3500);

      return () => clearTimeout(availableTimeout);
      //? //////////////////////////////////////////////////////////////////////////
    } else if (newNocTitle) {
      const newNocTimeout = setTimeout(() => {
        setIsNewNocFadingOut(true);
        const newNocFadeOutTimeout = setTimeout(() => {
          setNewNocTitle(false);
          setIsNewNocFadingOut(false);
        }, 1000);

        return () => clearTimeout(newNocFadeOutTimeout);
      }, 3500);

      return () => clearTimeout(newNocTimeout);
    }
  }, [
    totalNocTitle,
    availableCountyTitle,
    nocUsageTitle,
    ppUsageTitle,
    createdTitle,
    availableTitle,
    newNocTitle,
  ]);
  useEffect(() => {
    if (currentSession === "userSession") {
      if (currentUser?.user?.analytics?.nocUsage) {
        const nocYearsSet = new Set();

        // Loop through each item and add its year to the Set
        currentUser.user.analytics.nocUsage.forEach((item) => {
          nocYearsSet.add(item.year);
        });

        // Convert Set to array, sort it numerically, and update state
        setNocYears([...nocYearsSet].sort((a, b) => a - b));
      }
      if (currentUser?.user?.analytics?.ppUsage) {
        const ppYearsSet = new Set();

        // Loop through each item and add its year to the Set
        currentUser.user.analytics.ppUsage.forEach((item) => {
          ppYearsSet.add(item.year);
        });

        // Convert Set to array, sort it numerically, and update state
        setPPYears([...ppYearsSet].sort((a, b) => a - b));
      }
    } else if (currentSession === "subUserSession") {
      if (currentUser?.subuser?.nocUsage) {
        const nocYearsSet = new Set();

        // Loop through each item and add its year to the Set
        currentUser.subuser?.nocUsage.forEach((item) => {
          nocYearsSet.add(item.year);
        });

        // Convert Set to array, sort it numerically, and update state
        setNocYears([...nocYearsSet].sort((a, b) => a - b));
      }
      if (currentUser?.subuser?.ppUsage) {
        const ppYearsSet = new Set();

        // Loop through each item and add its year to the Set
        currentUser.subuser?.ppUsage.forEach((item) => {
          ppYearsSet.add(item.year);
        });

        // Convert Set to array, sort it numerically, and update state
        setPPYears([...ppYearsSet].sort((a, b) => a - b));
      }
    }
  }, []);

  useEffect(() => {
    if (
      currentUser &&
      currentUser?.role !== "notary" &&
      typeof currentUser?.user?.current_period_start === "number" &&
      typeof currentUser?.user?.current_period_end === "number"
    ) {
      let startMillis = currentUser?.user?.current_period_start * 1000;
      let endMillis = currentUser?.user?.current_period_end * 1000;

      // Optional: Check if the timestamps are positive numbers
      if (startMillis > 0 && endMillis > 0) {
        let startDateObj = DateTime.fromMillis(startMillis).setZone(timezone);
        let renewDateObj = DateTime.fromMillis(endMillis).setZone(timezone);

        if (startDateObj.isValid && renewDateObj.isValid) {
          let formattedStartDate = startDateObj.toFormat("LL/dd/yyyy");
          let formattedRenewDate = renewDateObj.toFormat("LL/dd/yyyy");

          setStartPeriod(formattedStartDate);
          setEndPeriod(formattedRenewDate);
        } else {
          return;
        }
      }
    }
  }, [timezone, currentUser]);

  useEffect(() => {
    if (currentUser?.subuser) {
      setTimezone(currentUser?.subuser?.timezone);
    } else {
      setTimezone(currentUser?.user?.personal[0]?.timezone);
    }

    setPaymentCards(
      currentUser?.payment_cards?.map((card) => {
        const lastTwo = String(card.exp_year).slice(-2);
        const first = card.exp_month;

        return {
          id: card.id,
          cardNum: "**** **** **** " + card.last4,
          cardName: card.name ? card.name : card.card_holder,
          expires:
            (first < 10 ? "0" + String(first) : String(first)) + "/" + lastTwo,
          default: card.default,
        };
      })
    );

    setLoading(false);
  }, [currentUser]);

  const handleNocChartValues = () => {
    let nocChartList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    if (currentSession === "userSession") {
      currentUser?.user?.analytics?.nocUsage?.map((item) => {
        if (item.year === nocYear) {
          nocChartList[item.month] = item.noc;
        }
        return null;
      });
    } else if (currentSession === "subUserSession") {
      currentUser?.subuser?.nocUsage?.map((item) => {
        if (item.year === nocYear) {
          nocChartList[item.month] = item.noc;
        }
        return null;
      });
    }

    return nocChartList;
  };

  const handlePPChartValues = () => {
    let ppChartList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    if (currentSession === "userSession") {
      currentUser?.user?.analytics?.ppUsage?.map((item) => {
        if (item.year === ppYear) {
          ppChartList[item.month] = item.package;
        }
        return null;
      });
    } else if (currentSession === "subUserSession") {
      currentUser?.subuser?.ppUsage?.map((item) => {
        if (item.year === ppYear) {
          ppChartList[item.month] = item.package;
        }
        return null;
      });
    }
    return ppChartList;
  };
  return (
    <>
      {loading ? (
        <>
          <Box sx={{ mb: 2 }}>
            <img
              style={{
                width: "10rem",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              alt="Permit Rockstar Logo"
              src="/img/website_img/PermitRockstar.png"
            />
          </Box>
          <LottieAnimation />
        </>
      ) : (
        <>
          <Box
            sx={{
              mx: largePC ? "auto" : 0,
              maxWidth: "1500px",
              py: 1,
              backgroundColor: "#EEF2F6",
              mb: tabletSize ? "5rem" : undefined,
            }}
          >
            <Grid
              container
              sx={{
                px: mobileSize ? 1 : tabletSize ? 2 : 3,
              }}
            >
              <Grid
                container
                spacing={mobileSize ? 1 : tabletSize ? 1.5 : 3}
                mt={0}
              >
                <Grid
                  item
                  xs={12}
                  sm={
                    currentUser?.tier?.monthly_restart &&
                    currentUser?.user?.analytics?.free_active
                      ? 6
                      : 12
                  }
                  md={
                    currentUser?.tier?.monthly_restart &&
                    currentUser?.user?.analytics?.free_active
                      ? 6
                      : 4
                  }
                  xl={
                    currentUser?.tier?.monthly_restart &&
                    currentUser?.user?.analytics?.free_active
                      ? 6
                      : undefined
                  }
                >
                  <CurrentPlan
                    headerText={
                      currentUser?.tier?.title === "Pay As You Go"
                        ? "Starter"
                        : currentUser?.tier?.title
                    }
                    subscriptionType={currentUser?.tier?.interval}
                    startPeriod={startPeriod}
                    endPeriod={endPeriod}
                    tabletSize={tabletSize}
                    mobileSize={mobileSize}
                    miniSize={miniSize}
                    between905and1125={between905and1125}
                    between1200and1405={between1200and1405}
                    between1400and1700={between1400and1700}
                    monthly={currentUser?.tier?.monthly_restart}
                    trial={currentUser?.trial}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={
                    currentUser?.tier?.monthly_restart &&
                    currentUser?.user?.analytics?.free_active
                      ? 6
                      : 6
                  }
                  md={
                    currentUser?.tier?.monthly_restart &&
                    currentUser?.user?.analytics?.free_active
                      ? 6
                      : 4
                  }
                  xl={
                    currentUser?.tier?.monthly_restart &&
                    currentUser?.user?.analytics?.free_active
                      ? 6
                      : undefined
                  }
                >
                  <TotalNoc
                    currentUser={currentUser}
                    tabletSize={tabletSize}
                    mobileSize={mobileSize}
                    miniSize={miniSize}
                    between905and1125={between905and1125}
                    between1200and1405={between1200and1405}
                    isFadingOut={isFadingOut}
                    totalNocTitle={totalNocTitle}
                    setTotalNocTitle={setTotalNocTitle}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={
                    currentUser?.tier?.monthly_restart &&
                    currentUser?.user?.analytics?.free_active
                      ? 6
                      : 6
                  }
                  md={
                    currentUser?.tier?.monthly_restart &&
                    currentUser?.user?.analytics?.free_active
                      ? 6
                      : 4
                  }
                  xl={
                    currentUser?.tier?.monthly_restart &&
                    currentUser?.user?.analytics?.free_active
                      ? 6
                      : undefined
                  }
                >
                  <TotalCounties
                    difference={16}
                    positive={false}
                    tabletSize={tabletSize}
                    mobileSize={mobileSize}
                    miniSize={miniSize}
                    between905and1125={between905and1125}
                    between1200and1405={between1200and1405}
                    value={
                      currentUser?.user?.role === "admin" ||
                      currentUser?.user?.role === "tester" ||
                      currentUser?.user?.admin === true
                        ? 67
                        : parseInt(currentUser?.tier?.county) +
                          parseInt(
                            currentUser?.user?.analytics?.additions.numCounties
                          )
                    }
                    isFadingOut={isCountyFadingOut}
                    availableCountyTitle={availableCountyTitle}
                    setAvailableCountyTitle={setAvailableCountyTitle}
                  />
                </Grid>
                {currentUser?.tier?.monthly_restart &&
                  currentUser?.user?.analytics?.free_active &&
                  currentUser?.user?.analytics?.nocCreatedInPeriod <
                    currentUser?.user?.analytics?.availableNocInPeriod && (
                    <Grid item xs={12} sm={6} md={6} xl={6}>
                      <NewNoc
                        text={currentUser?.user?.analytics.free_days_left}
                        value={currentUser?.user?.analytics.nocLeftBar}
                        tabletSize={tabletSize}
                        mobileSize={mobileSize}
                        between1200and1405={between1200and1405}
                        isFadingOut={isNewNocFadingOut}
                        newNocTitle={newNocTitle}
                        setNewNocTitle={setNewNocTitle}
                      />
                    </Grid>
                  )}
              </Grid>
              <Grid
                container
                spacing={mobileSize ? 1 : tabletSize ? 1.5 : 3}
                mt={0}
              >
                <Grid
                  item
                  xs={12}
                  md={
                    currentUser?.tier?.monthly_restart &&
                    currentUser?.user?.analytics?.free_active
                      ? 8
                      : 12
                  }
                  lg={
                    currentUser?.tier?.monthly_restart &&
                    currentUser?.user?.analytics?.free_active
                      ? 8
                      : 12
                  }
                >
                  <PpUsage
                    year={ppYear}
                    setYear={setPPYear}
                    nocYears={ppYears}
                    tabletSize={tabletSize}
                    mobileSize={mobileSize}
                    handleChartValues={handlePPChartValues}
                    chartSeries={[
                      {
                        name: "Created Permits",
                        data: handlePPChartValues(),
                      },
                    ]}
                    sx={{ height: "100%" }}
                    isFadingOut={isPPUsageFadingOut}
                    usageTitle={ppUsageTitle}
                    setUsageTitle={setPPUsageTitle}
                  />
                </Grid>
                {currentUser?.tier?.monthly_restart &&
                  currentUser?.user?.analytics?.free_active && (
                    <Grid item xs={12} md={4} lg={4}>
                      {currentUser ? (
                        <AvailableNoc
                          chartSeries={
                            currentUser?.user?.role === "admin" ||
                            currentUser?.user?.role === "tester" ||
                            currentUser?.user?.admin === true
                              ? [
                                  100000000000000,
                                  parseInt(
                                    currentUser?.user?.analytics
                                      .nocCreatedInPeriod
                                  ),
                                ]
                              : [
                                  currentUser?.user?.analytics
                                    ?.availableNocInPeriod === "unlimited"
                                    ? 100000000000000
                                    : parseInt(
                                        currentUser?.user?.analytics
                                          ?.availableNocInPeriod
                                      ) -
                                      parseInt(
                                        currentUser?.user?.analytics
                                          .nocCreatedInPeriod
                                      ),
                                  parseInt(
                                    currentUser?.user?.analytics
                                      .nocCreatedInPeriod
                                  ),
                                ]
                          }
                          labels={["Available", "Used"]}
                          role={currentUser?.user?.role}
                          admin={currentUser?.user?.admin}
                          tier={parseInt(currentUser?.tier?.noc)}
                          sx={{ height: "100%" }}
                          tabletSize={tabletSize}
                          mobileSize={mobileSize}
                          between1200and1405={between1200and1405}
                          isFadingOut={isAvailableFadingOut}
                          availableTitle={availableTitle}
                          setAvailableTitle={setAvailableTitle}
                        />
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                  )}
                <Grid
                  item
                  xs={12}
                  md={
                    currentUser?.tier?.monthly_restart &&
                    currentUser?.user?.analytics?.free_active
                      ? 12
                      : 12
                  }
                  lg={
                    currentUser?.tier?.monthly_restart &&
                    currentUser?.user?.analytics?.free_active
                      ? 12
                      : 12
                  }
                >
                  <NocUsage
                    year={nocYear}
                    setYear={setNocYear}
                    nocYears={nocYears}
                    tabletSize={tabletSize}
                    mobileSize={mobileSize}
                    handleChartValues={handleNocChartValues}
                    chartSeries={[
                      {
                        name: "Created NOCs",
                        data: handleNocChartValues(),
                      },
                    ]}
                    sx={{ height: "100%" }}
                    isFadingOut={isNocUsageFadingOut}
                    usageTitle={nocUsageTitle}
                    setUsageTitle={setNocUsageTitle}
                  />
                </Grid>
              </Grid>
            </Grid>
            {tabletSize && <MobileFooter pos="bottom" pl="0.5rem" />}
          </Box>
          {/* <WelcomeModal
            open={false}
            setOpen={setOpenWelcome}
            currentSession={currentSession}
          /> */}
        </>
      )}
    </>
  );
};

export default Dashboard;
