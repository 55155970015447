import axios from "axios";
import { DateTime } from "luxon";
import { invalidateSession, setRole } from "./sessionsActions";

export const setInstantRonAccepted = () => {
  return { type: "SET_INSTANT_RON_ACCEPTED" };
};
export const setInstantRonStop = () => {
  return { type: "SET_INSTANT_RON_STOP" };
};
export const setInstantRonSuccess = () => {
  return { type: "SET_INSTANT_RON_SUCCESS" };
};
export const setInstantRonInitialState = () => {
  return { type: "SET_INSTANT_RON_INITIAL_STATE" };
};

export const updateNotaryUserForms = (notaryForm) => {
  return {
    type: "UPDATE_NOTARY_USER_FORMS",
    payload: notaryForm,
  };
};

export const updatePaymentCards = (cards) => {
  return {
    type: "UPDATE_PAYMENT_CARDS",
    payload: cards,
  };
};

export const updateAnalytics = (analytics) => {
  return {
    type: "UPDATE_ANALYTICS",
    payload: analytics,
  };
};

export const updateNocTasks = (nocTasks) => {
  return {
    type: "UPDATE_NOC_TASKS",
    payload: nocTasks,
  };
};

export const addContractor = (cData) => {
  return {
    type: "ADD_CONTRACTOR",
    payload: cData,
  };
};

export const updateContractor = (cData) => {
  return {
    type: "UPDATE_CONTRACTOR",
    payload: cData,
  };
};

export const userFirstLoginOff = () => {
  return {
    type: "USER_FIRST_LOGIN_OFF",
  };
};

export const updateSigneeEmailAction =
  (source, id, newEmail) => async (dispatch, getState) => {
    const { userSession } = getState();

    // Check if user exists in the state and if not, return the current state
    if (!userSession?.user) return userSession;

    // Update the list within notary_forms based on the source
    const updatedList = userSession?.user?.notary_forms[source].map((item) =>
      item.id === id
        ? { ...item, email: newEmail, signee_reg_email: newEmail }
        : item
    );

    dispatch({
      type: "UPDATE_SIGNEE_EMAIL",
      payload: { source, updatedList },
    });
  };

export const updateURLs = (urlData) => {
  return {
    type: "UPDATE_URLS",
    payload: urlData,
  };
};

export const addURL = (urlData) => {
  return {
    type: "ADD_URL",
    payload: urlData,
  };
};

export const updateUser = (userData) => {
  return {
    type: "UPDATE_USER",
    payload: userData,
  };
};

export const addURLtoCreatedForm = () => async (dispatch, getState) => {
  const { userSession } = getState();
  // Check if user, nocUserForms, and urls exist in the state, if not, return the current state.
  if (
    !userSession?.user ||
    !userSession?.user?.nocUserForms ||
    !userSession?.urls
  )
    return userSession;

  // Create a copy of nocUserForms to update
  let updatedNocUserForms = [...userSession?.user?.nocUserForms];

  // Loop through each url in the urls array
  userSession?.urls?.forEach((url) => {
    // Extract noc_id and signed from the url object, assuming these properties exist
    const { noc_id, signed } = url;

    // Find the index of the form in nocUserForms that matches the noc_id
    const formIndex = updatedNocUserForms.findIndex(
      (form) => form.id === noc_id
    );

    // If a matching form is found, update its signed property
    if (formIndex !== -1) {
      updatedNocUserForms[formIndex] = {
        ...updatedNocUserForms[formIndex],
        signed,
      };
    }
  });

  dispatch({
    type: "ADD_URL_TO_CREATED_FORM",
    payload: updatedNocUserForms,
  });
};

// export const broadcastUpdate = (sharedUserData) => {
//   return {
//     type: "BROADCAST_UPDATE",
//     payload: sharedUserData,
//   };
// };

export const addUserFullDesignProfessionals = (data) => {
  return {
    type: "ADD_USER_FULL_DESIGN_PROFESSIONALS",
    payload: data,
  };
};

export const getNotification = (notification) => {
  return {
    type: "GET_NOTIFICATION",
    payload: notification,
  };
};

export const updateUserBuildingPermit = (data) => {
  return {
    type: "UPDATE_NOC_FORMS",
    payload: data,
  };
};

export const pushNewDesignPro = (data) => {
  return {
    type: "PUSH_NEW_DESIGN_PRO",
    payload: data,
  };
};

export const updateNewDesignPro = (data) => {
  return {
    type: "UPDATE_DESIGN_PRO",
    payload: data,
  };
};

export const removeDesignPro = (id) => {
  return {
    type: "REMOVE_DESIGN_PRO",
    payload: id,
  };
};

export const updateNocForms = (sessionName) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/data/update-urls`,
      { sessionName: sessionName },
      { withCredentials: true }
    );

    // Dispatch the action to update user data
    dispatch({
      type: "UPDATE_NOC_FORMS",
      payload: res?.data?.data,
    });

    return res?.data?.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchUserData = (sessionName) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/auth/getuser`,
      { sessionName: sessionName },
      { withCredentials: true }
    );

    // Dispatch the action to update user data
    localStorage.removeItem("emailVerified");
    dispatch(updateUser(res.data.data));

    return res.data;
  } catch (error) {
    const { sessions } = getState();
    dispatch(invalidateSession(sessions.currentSession));
    return false;
  }
};

// export const sendWebhookData = (payload) => async (dispatch, getState) => {
//   try {
//     await axios.post(
//       `${process.env.REACT_APP_BASE_URL}/nocApi_webhook/process-recording-status`,
//       payload,
//       { withCredentials: true }
//     );
//   } catch (error) {
//     // Handle the error
//   }
// };

export const sendBlueNotaryWebhookData =
  (payload) => async (dispatch, getState) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi_webhook/blue-notary/webhook`,
        payload,
        { withCredentials: true }
      );
      return;
    } catch (error) {
      // Handle the error
    }
  };

export const fetchNotaryUserForms = () => async (dispatch, getState) => {
  try {
    const { sessions } = getState();
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/data/update-notary-user-forms`,
      { currentSession: sessions?.currentSession },
      { withCredentials: true }
    );

    // Dispatch the action to update user data
    dispatch(updateNotaryUserForms(res.data.data));

    return res.data;
  } catch (error) {}
};

export const loginUser =
  (
    defEmail,
    pwd,
    remember,
    recaptchaValue,
    count,
    oldEmail,
    rememberInStorage
  ) =>
  async (dispatch) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/beforelogin`,
        {
          email: defEmail,
          oldEmail: oldEmail,
          password: pwd,
          remember: remember,
          value: recaptchaValue,
          count: count,
          rememberInStorage: rememberInStorage,
        },
        { withCredentials: true }
      );

      if (!res.data || res.data.success !== true) {
        return {
          success: false,
          message: "We are updating our application. Please come back later",
        };
      }

      if (res.data.success === true) {
        // Store session-specific data in sessionStorage
        const expiryDuration = parseInt(process.env.REACT_APP_SESSION_EXP);
        const currentUtcTime = DateTime.utc();
        const expiryTime = currentUtcTime
          .plus({ seconds: expiryDuration })
          .toSeconds();

        sessionStorage.setItem(
          "__nocCreator__r_rid",
          JSON.stringify(res?.data?.role)
        );
        sessionStorage.setItem("exp", Math.floor(expiryTime));

        dispatch(setRole(res?.data?.role));

        return {
          success: true,
          message: res.data.message,
          data: res.data,
        };
      } else {
        return {
          success: false,
          message: res.data.message,
        };
      }
    } catch (err) {
      if (err.code === "ERR_NETWORK") {
        return {
          success: false,
          message: "We are updating our application. Please come back later",
        };
      }

      return {
        success: false,
        message: "Invalid Credentials",
      };
    }
  };
