import {
  // broadcastUpdate,
  updateUser,
  sendWebhookData,
  updatePaymentCards,
  updateNocTasks,
  getNotification,
  updateNotaryUserForms,
  addURL,
  addUserFullDesignProfessionals,
  setInstantRonAccepted,
  setInstantRonStop,
  setInstantRonSuccess,
  sendBlueNotaryWebhookData,
  updateAnalytics,
} from "../userActions";

import {
  getLiveNocData,
  getPdfProgress,
  updatePermitPackageFromReturn,
  updatePermitPackageOnFly,
  updateNotification,
  updateNotificationCount,
  addNewNotification,
  pushCityLog,
} from "../dataActions";

export const userSessionOnMessage =
  (receivedData) => async (dispatch, getState) => {
    switch (receivedData.event) {
      case "instant_ron_accepted":
        const ronAcceptedUpdate = receivedData.data;
        if (ronAcceptedUpdate.success === true) {
          dispatch(setInstantRonAccepted());
          break;
        } else if (ronAcceptedUpdate.success === "stop") {
          dispatch(setInstantRonStop());
          break;
        } else if (ronAcceptedUpdate.success === "success") {
          dispatch(setInstantRonSuccess());
          break;
        }
        break;
      case "add_new_notification":
        dispatch(addNewNotification(receivedData?.data));
        break;
      case "notification_update":
        dispatch(updateNotification(receivedData?.data));
        break;
      case "update_notification_count":
        dispatch(updateNotificationCount(receivedData?.data));
        break;
      case "user_update":
        dispatch(updateUser(receivedData?.data));
        break;
      case "update_notary_user_forms":
        dispatch(updateNotaryUserForms(receivedData.data));
        break;
      // case "recording_update":
      //   dispatch(sendWebhookData(receivedData?.data));
      //   break;
      //! MANUAL WEBHOOK TRIGGER
      // case "ron_session_completed":
      //   dispatch(sendBlueNotaryWebhookData(receivedData?.data));
      //   break;
      //! ////////////////////////
      case "update_payment_cards":
        dispatch(updatePaymentCards(receivedData?.data));
        break;
      case "update_noc_tasks":
        dispatch(updateNocTasks(receivedData?.data));
        break;
      // case "notification_update":
      //   dispatch(getNotification([receivedData.data]));
      //   break;
      case "broadcast":
        dispatch(getLiveNocData(receivedData.data));
        break;
      case "add_url_img":
        dispatch(addURL(receivedData.data?.data));
        break;
      case "update_pdf_progress":
        dispatch(getPdfProgress(receivedData.data));
        break;
      case "design_professionals_update":
        dispatch(addUserFullDesignProfessionals(receivedData.data));
        break;
      case "update_permit_document":
        dispatch(updatePermitPackageFromReturn(receivedData.data));
        break;
      case "update_permit_package_on_fly":
        console.log("WS UPDATE");
        console.log(receivedData.data);
        dispatch(updatePermitPackageOnFly(receivedData.data));
        break;
      case "push_city_log":
        dispatch(pushCityLog(receivedData.data));
        break;
      case "update_analytics":
        dispatch(updateAnalytics(receivedData.data));
        break;
      default:
        return;
    }
  };
