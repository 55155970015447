import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";

import { TourProvider, useTour } from "@reactour/tour";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBack from "@mui/icons-material/ArrowBack";

import { useDispatch, useSelector } from "react-redux";
import {
  validateSession,
  resetSession,
  validateRole,
  logoutHandler,
} from "./store/actions/sessionsActions";
import { setExpiredSession } from "./store/actions/sessionsActions";
import { withSessionCheck } from "./components/hoc/withSessionCheck";
import {
  connectWebsocket,
  disconnectWebsocket,
} from "./store/actions/websocketActions";
import { DateTime } from "luxon";
import SSORedirect from "./components/auth/SSORedirect";
import Home from "./components/pages/Home";
import Subscription from "./components/pages/Subscription";
import SigneeLogin from "./components/signee/SigneeLogin";
import Login from "./components/pages/Login";
import AcceptInstant from "./components/notary/AcceptInstant";
import SuccessChangePlan from "./components/SuccessChangePlan";
import SuccessCreateExpiredSubscription from "./components/SuccessCreateExpiredSubscription";
import Register from "./components/pages/Register";
import Dashboard from "./components/pages/Dashboard";
import CreateNoc from "./components/pages/CreateNoc";
import Payment from "./components/pages/Payment";
import SelectCounties from "./components/pages/SelectCounties";
import Presets from "./components/pages/Presets";
import Contractors from "./components/pages/Contractors";
import ProfileSettings from "./components/pages/ProfileSettings";
import CustomerService from "./components/pages/CustomerService";
import Overview from "./components/pages/admin/Overview";
import Management from "./components/pages/admin/Management";
import Messages from "./components/pages/admin/Messages";
import Licenses from "./components/pages/admin/Licenses";
import Tasks from "./components/pages/admin/Tasks";
import { Layout } from "./components/layout/Layout";
import ResetPassword from "./components/pages/ResetPassword";
import { ClerkProvider } from "@clerk/clerk-react";
import Customers from "./components/pages/admin/Customers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DynamicAuth from "./components/pages/DynamicAuth";
import SubuserFinalize from "./components/pages/SubuserFinalize";
import "./styles/globals.css";
import TotpAuth from "./components/pages/TotpAuth";
import EmailFaAuth from "./components/pages/EmailFaAuth";
import EmailFaAuthPass from "./components/pages/EmailFaAuthPass";
import SmsFaAuth from "./components/pages/SmsFaAuth";

import NotaryRegister from "./components/notary/NotaryRegister";
import RegisterSignee from "./components/notary/signee/RegisterSignee";
import RegisterRecordSignee from "./components/notary/signee/RegisterRecordSignee";

import ManageSigneeAppointment from "./components/signee/ManageSigneeAppointment";

// import EmbeddedSigningComponent from "./components/securedSigning/EmbeddedSigningComponent";

import TotpResetAuth from "./components/pages/TotpResetAuth";
import EmailResetFaAuth from "./components/pages/EmailResetFaAuth";
import EmailFaResetAuthPass from "./components/pages/EmailFaResetAuthPass";
import SmsFaResetAuth from "./components/pages/SmsFaResetAuth";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Subusers from "./components/pages/Subusers";
import Notaries from "./components/pages/admin/Notaries";
import NotaryDashboard from "./components/notary/NotaryDashboard";
import NotaryJobManagement from "./components/notary/NotaryJobManagement";
import FinishNotary from "./components/notary/FinishNotary";
import NotarySettings from "./components/notary/NotarySettings";
import MyPermits from "./components/pages/permits/MyPermits";
import NotarizationRequests from "./components/pages/permits/NotarizationRequests";
import Uploader from "./components/pages/Uploader";
import NotFound from "./components/pages/NotFound";
import { setCurrentSession } from "./store/actions/sessionsActions";

import {
  checkRoleValidationNeeded,
  getSessionNameByPath,
  isPathProtected,
} from "./functions/helperFunctions";
import NotaryCustomerService from "./components/pages/NotaryCustomerService";
import BuildingPermit from "./components/pages/BuildingPermit";
import UserRonCompleted from "./components/pages/ron/UserRonCompleted";
import NotaryRonCompleted from "./components/pages/ron/NotaryRonCompleted";
import { subUserTourSteps, tourSteps } from "./data/walkthroughDemo/steps";
import { LoadingButton } from "@mui/lab";
import { clearShowDemo, onReadyToFetch } from "./store/actions/dataActions";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { setIsOpen, currentStep } = useTour();
  // const isReadyToFetch = useSelector((state) => state.nocData?.isReadyToFetch);
  const showTourNav = useSelector((state) => state?.nocData?.showTourNav);
  const tabletSize = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const sessions = useSelector((state) => state.sessions);
  const currentRole = useSelector((state) => state?.sessions?.currentRole);

  // useEffect(() => {
  //   if (process.env.REACT_APP_PROCESS_TYPE === "PRODUCTION") {
  //     // Disable right-click context menu
  //     const disableContextMenu = (e) => {
  //       e.preventDefault();
  //     };
  //     window.addEventListener("contextmenu", disableContextMenu);

  //     // Detect if Developer Tools is open
  //     const detectDevTools = (e) => {
  //       if (e.ctrlKey && e.shiftKey && (e.key === "I" || e.key === "i")) {
  //         // alert("Developer Tools is disabled!");
  //         e.preventDefault();
  //       }
  //     };
  //     window.addEventListener("keydown", detectDevTools);

  //     // Clean up event listeners
  //     return () => {
  //       window.removeEventListener("contextmenu", disableContextMenu);
  //       window.removeEventListener("keydown", detectDevTools);
  //     };
  //   }
  // }, []);

  // useEffect(() => {
  //   const roleHandler = setTimeout(() => {
  //     const handleRoleValidation = async () => {
  //       const validatedRole = await dispatch(validateRole());

  //       if (validatedRole === false) {
  //         navigate("/login");
  //         setIsOpen(false);
  //       }
  //     };

  //     const needRoleValidation = checkRoleValidationNeeded(location.pathname);
  //     if (needRoleValidation && !currentRole) {
  //       handleRoleValidation();
  //     }
  //   }, 500);

  //   return () => {
  //     clearTimeout(roleHandler);
  //   };
  // }, [location.pathname]);

  const exitHandler = async (sessionName) => {
    console.log("Exiting session...");
    sessionStorage.removeItem("__nocCreator__r_rid");
    sessionStorage.removeItem("exp");
    dispatch(setExpiredSession(true));
    console.log("setExpiredSession...");

    console.log("Disconnecting Websocket... with session: ", sessionName);
    await dispatch(disconnectWebsocket(sessionName));
    dispatch(clearShowDemo());
    console.log("Navigate to login");
    navigate("/login");
    setIsOpen(false);
    console.log("exitHandler done...");
  };

  // useEffect(() => {
  //   const checkSessionExpired = setInterval(async () => {
  //     // Get the current time in UTC (in seconds)
  //     const timeNow = Math.floor(DateTime.utc().toSeconds());

  //     // Retrieve sessionName and expiry from sessionStorage
  //     const sessionName = sessionStorage.getItem("__nocCreator__r_rid");
  //     const expiry = sessionStorage.getItem("exp");

  //     // Check if expiry exists and if the current time has passed the expiry
  //     if (expiry && timeNow > expiry) {
  //       await exitHandler(sessionName); // Call your exit handler if the session has expired
  //     }
  //   }, 5000); // Check every 5 seconds

  //   // Cleanup interval on component unmount
  //   return () => clearInterval(checkSessionExpired);
  // }, []);

  const validateSessionAndRole = useCallback(async () => {
    const timeNow = Math.floor(DateTime.utc().toSeconds());
    const sessionName = sessionStorage.getItem("__nocCreator__r_rid");
    const expiry = sessionStorage.getItem("exp");

    console.log("Validating session and role...");
    console.log("currentRole:", currentRole);
    console.log("timeNow:", timeNow);
    console.log("expiry:", expiry);
    console.log("sessionName:", sessionName);

    // If session has expired, log the user out
    if (expiry && timeNow > expiry) {
      console.log("Session expired, logging out...");
      await exitHandler(sessionName);
      return;
    }

    // Validate role if needed
    const needRoleValidation = checkRoleValidationNeeded(location.pathname);

    console.log("needRoleValidation:", needRoleValidation);

    if (needRoleValidation && !currentRole) {
      console.log("Validating Role...");
      const validatedRole = await dispatch(validateRole());
      console.log("validatedRole:", validatedRole);
      if (validatedRole === false) {
        navigate("/login");
        setIsOpen(false);
      }
    }
  }, [currentRole, dispatch, location.pathname, navigate, setIsOpen]);

  useEffect(() => {
    // Debounce setup with interval
    const intervalId = setInterval(() => {
      const debouncedHandler = setTimeout(() => {
        validateSessionAndRole();
      }, 1300); // Debounce delay within the interval

      // Clear timeout if the effect reruns before the interval completes
      return () => clearTimeout(debouncedHandler);
    }, 5000); // Interval set to 5000ms (5 seconds)

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [validateSessionAndRole]);

  useEffect(() => {
    const handler = setTimeout(() => {
      const checkSession = async () => {
        // Determine session details based on the current path
        const sessionDetails = getSessionNameByPath(
          location.pathname,
          currentRole
        );
        //! sessionDetails => MEAN IT'S HIT A PROTECTED ROUTE!!!!
        if (sessionDetails) {
          const { sessionName, requiredCookie, needSocket } = sessionDetails;
          const sessionToken = sessions[sessionName]?.token;
          const validated = sessions[sessionName]?.isValidated;
          const wsConnected = sessions[sessionName]?.wsConnected;

          if (
            !sessions?.currentSession &&
            validated &&
            sessionToken &&
            wsConnected
          ) {
            dispatch(setCurrentSession(sessionName));
          } else if (!sessionToken || !validated) {
            const validated = await dispatch(
              validateSession(sessionName, requiredCookie, needSocket)
            );
            if (validated === false) {
              navigate("/login");
            }
          } else if (sessionToken && validated && !wsConnected) {
            dispatch(connectWebsocket(sessionName, sessionToken));
          }
        } else {
          if (sessions?.currentSession) {
            dispatch(disconnectWebsocket(sessions?.currentSession));
            dispatch(resetSession(sessions?.currentSession));
          } else if (
            sessions?.currentRole &&
            sessions?.currentRole?.length > 0 &&
            isPathProtected(location.pathname)
          ) {
            navigate(-1);
          }
        }
      };

      checkSession();
    }, 1200);

    return () => {
      clearTimeout(handler);
    };
  }, [location.pathname, sessions, currentRole]);

  const DashboardComponent = withSessionCheck(Dashboard, [
    "userSession",
    "subUserSession",
  ]);
  const CreateNocComponent = withSessionCheck(CreateNoc, [
    "userSession",
    "subUserSession",
  ]);
  const BuildingPermitComponent = withSessionCheck(BuildingPermit, [
    "userSession",
    "subUserSession",
  ]);
  const SelectCountiesComponent = withSessionCheck(SelectCounties, [
    "userSession",
    "subUserSession",
  ]);
  const PresetsComponent = withSessionCheck(Presets, [
    "userSession",
    "subUserSession",
  ]);
  const MyPermitsComponent = withSessionCheck(
    MyPermits,
    ["userSession", "subUserSession"],
    Elements,
    {
      stripe: stripePromise,
    }
  );
  const NotarizationRequestsComponent = withSessionCheck(
    NotarizationRequests,
    ["userSession", "subUserSession"],
    Elements,
    {
      stripe: stripePromise,
    }
  );
  const UploaderComponent = withSessionCheck(Uploader, [
    "userSession",
    "subUserSession",
  ]);
  const ProfileSettingsComponent = withSessionCheck(ProfileSettings, [
    "userSession",
    "subUserSession",
  ]);
  const ContractorsComponent = withSessionCheck(Contractors, ["userSession"]);
  const PaymentComponent = withSessionCheck(
    Payment,
    ["userSession", "subUserSession"],
    Elements,
    {
      stripe: stripePromise,
    }
  );
  const SubusersComponent = withSessionCheck(Subusers, ["userSession"]);
  const SubscriptionComponent = withSessionCheck(Subscription, [
    "userSession",
    "subUserSession",
  ]);
  const CustomerServiceComponent = withSessionCheck(CustomerService, [
    "userSession",
    "subUserSession",
  ]);
  //! ADMIN ONLY ROUTES ///////////////////////////////////////////////////////////////////////////
  const OverviewComponent = withSessionCheck(Overview, ["userSession"]);
  const CustomersComponent = withSessionCheck(Customers, ["userSession"]);
  const TasksComponent = withSessionCheck(Tasks, ["userSession"]);
  const LicensesComponent = withSessionCheck(Licenses, ["userSession"]);
  const ManagementComponent = withSessionCheck(Management, ["userSession"]);
  const MessagesComponent = withSessionCheck(Messages, ["userSession"]);
  const NotariesComponent = withSessionCheck(Notaries, ["userSession"]);
  //! ////////////////////////////////////////////////////////////////////////////////////////
  const NotaryDashboardComponent = withSessionCheck(NotaryDashboard, [
    "notarySession",
  ]);
  const NotaryCustomerServiceComponent = withSessionCheck(
    NotaryCustomerService,
    ["notarySession"]
  );
  // const NotaryQueueComponent = withSessionCheck(NotaryQueue, "notarySession");
  const NotaryJobManagementComponent = withSessionCheck(NotaryJobManagement, [
    "notarySession",
  ]);
  const NotarySettingsComponent = withSessionCheck(NotarySettings, [
    "notarySession",
  ]);
  const FinishNotaryComponent = withSessionCheck(FinishNotary, [
    "notarySession",
  ]);

  // ! SIGNEE ROUTES - GUEST SESSION /////////////////////////////////////////////////////////////////////
  const RegisterSigneeComponent = withSessionCheck(
    RegisterSignee,
    ["guestSession"],
    null,
    {},
    false
  );
  const RegisterRecordSigneeComponent = withSessionCheck(
    RegisterRecordSignee,
    ["guestSession"],
    null,
    {},
    false
  );
  const ManageSigneeAppointmentComponent = withSessionCheck(
    ManageSigneeAppointment,
    ["guestSession"],
    null,
    {},
    false
  );
  //! /////////////////////////////////////////////////////////////////////////////////////////////////////
  // const showNavUserList = [
  //   1, 6, 13, 14, 15, 16, 17, 18, 19, 20, 22, 24, 26, 27, 28, 29,
  // ];
  const showNavUserList = [
    1, 6, 10, 11, 13, 14, 15, 16, 17, 18, 19, 20, 22, 24, 26, 27, 28, 29, 30,
  ];
  const showNavSubList = [1, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

  const userLoadingBtn = [30];
  const subUserLoadingBtn = [20];

  const disableUserPrev = [0, 1, 30];
  const disableSubUserPrev = [0, 1, 20];

  const renderMainComponents = () =>
    tabletSize &&
    [
      "/",
      "/login",
      "/register",
      "/signee/auth-login",
      "/notary/signee/register",
      "/record/signee/register",
      "/signee/manage",
      "/resetpassword",
      "/2fa-secure",
      "/2fa-secure/email",
      "/2fa-secure/totp",
      "/2fa-secure/sms",
      "/2fa-secure/email/pass",
      "/ron/user/completed",
    ].includes(location.pathname) ? (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />

        <Route path="/signee/auth-login" element={<SigneeLogin />} />
        <Route
          path="/notary/signee/register"
          element={<RegisterSigneeComponent />}
        />
        <Route
          path="/record/signee/register"
          element={<RegisterRecordSigneeComponent />}
        />
        <Route
          path="/signee/manage"
          element={<ManageSigneeAppointmentComponent />}
        />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/2fa-secure" element={<DynamicAuth />} />
        <Route path="/2fa-secure/email" element={<EmailFaAuth />} />
        <Route path="/2fa-secure/totp" element={<TotpAuth />} />
        <Route path="/2fa-secure/sms" element={<SmsFaAuth />} />
        <Route path="2fa-secure/email/pass" element={<EmailFaAuthPass />} />
        <Route path="/ron/user/completed" element={<UserRonCompleted />} />
      </Routes>
    ) : tabletSize ? (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          textAlign: "center",
          padding: "20px",
        }}
      >
        <Typography variant="h4" component="h1">
          Screen Size Unsupported
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          Our application is currently being optimized for smaller screens.
          Please switch to a device with a larger display to continue, or check
          back later for mobile support.
        </Typography>
        {currentRole && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <LoadingButton
              onClick={async () => {
                await exitHandler(
                  sessionStorage.getItem("__nocCreator__r_rid")
                );
              }}
            >
              Log out
            </LoadingButton>
          </Box>
        )}
      </Box>
    ) : (
      <Routes>
        <Route path="/" element={<Home />} />
        {/* userSession */}
        <Route path="/dashboard" element={<DashboardComponent />} />

        <Route path="/permits/create" element={<CreateNocComponent />} />
        <Route path="/building-permit" element={<BuildingPermitComponent />} />
        <Route path="/selectcounties" element={<SelectCountiesComponent />} />
        <Route path="/defaults" element={<PresetsComponent />} />
        <Route path="/permits/my-permits" element={<MyPermitsComponent />} />
        <Route
          path="/permits/invitations"
          element={<NotarizationRequestsComponent />}
        />
        <Route path="/document-uploader" element={<UploaderComponent />} />
        <Route path="/profilesettings" element={<ProfileSettingsComponent />} />
        <Route path="/contractors" element={<ContractorsComponent />} />
        <Route path="/payment" element={<PaymentComponent />} />
        <Route path="/subusers" element={<SubusersComponent />} />
        <Route path="/subscription" element={<SubscriptionComponent />} />
        <Route path="/contact" element={<CustomerServiceComponent />} />
        {/* userSession -> ADMIN */}
        <Route path="/admin/notaries" element={<NotariesComponent />} />
        <Route path="/admin/overview" element={<OverviewComponent />} />
        <Route path="/admin/customers" element={<CustomersComponent />} />
        <Route path="/admin/tasks" element={<TasksComponent />} />
        <Route path="/admin/licenses" element={<LicensesComponent />} />
        <Route path="/admin/management" element={<ManagementComponent />} />
        <Route path="/admin/messages" element={<MessagesComponent />} />
        {/* notarySession */}
        <Route
          path="/notary/dashboard"
          element={<NotaryDashboardComponent />}
        />
        {/* <Route path="/notary/queue" element={<NotaryQueueComponent />} /> */}
        <Route
          path="/notary/history"
          element={<NotaryJobManagementComponent />}
        />
        <Route path="/notary/settings" element={<NotarySettingsComponent />} />
        <Route path="/notary/finalize" element={<FinishNotaryComponent />} />
        {/* ************************************************************************************ */}
        {/* guestSession */}
        <Route
          path="/notary/signee/register"
          element={<RegisterSigneeComponent />}
        />
        <Route
          path="/record/signee/register"
          element={<RegisterRecordSigneeComponent />}
        />
        <Route
          path="/signee/manage"
          element={<ManageSigneeAppointmentComponent />}
        />
        {/* ********************** */}
        {/* STRIPE */}
        <Route path="/changed-plan" element={<SuccessChangePlan />} />
        <Route
          path="/created-expired-plan"
          element={<SuccessCreateExpiredSubscription />}
        />
        {/* SUBUSER */}
        <Route path="/subuser/finalize" element={<SubuserFinalize />} />
        {/* 2FA */}
        <Route path="/2fa-secure" element={<DynamicAuth />} />
        <Route path="/2fa-secure/totp" element={<TotpAuth />} />
        <Route path="/2fa-secure/sms" element={<SmsFaAuth />} />
        <Route path="/2fa-secure/email" element={<EmailFaAuth />} />
        <Route path="2fa-secure/email/pass" element={<EmailFaAuthPass />} />
        {/* NOTARY */}
        <Route path="/notary/register" element={<NotaryRegister />} />
        <Route path="/notary/accept-instant" element={<AcceptInstant />} />
        <Route
          path="/notary/contact"
          element={<NotaryCustomerServiceComponent />}
        />
        <Route path="/2fa-secure/reset-pwd/totp" element={<TotpResetAuth />} />
        <Route path="/2fa-secure/reset-pwd/sms" element={<SmsFaResetAuth />} />
        <Route
          path="2fa-secure/reset-pwd/email"
          element={<EmailResetFaAuth />}
        />
        <Route
          path="2fa-secure/email/reset/pass"
          element={<EmailFaResetAuthPass />}
        />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signee/auth-login" element={<SigneeLogin />} />
        {/* RON COMPLETION PAGES */}
        <Route path="/ron/notary/completed" element={<NotaryRonCompleted />} />
        <Route path="/ron/user/completed" element={<UserRonCompleted />} />

        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {sessions?.currentSession ? (
        <TourProvider
          steps={
            sessions?.currentSession &&
            sessions?.currentSession === "subUserSession"
              ? subUserTourSteps
              : tourSteps
          }
          // startAt={5}
          disableKeyboardNavigation={["esc", "left", "right"]}
          showDots={false}
          showNavigation={true}
          badgeContent={({ totalSteps, currentStep }) =>
            currentStep + 1 + "/" + (totalSteps - 1)
          }
          nextButton={({
            currentStep,
            stepsLength,
            setIsOpen,
            setCurrentStep,
            steps,
          }) => {
            const last = currentStep === stepsLength - 2;
            const isUser = sessions?.currentSession === "userSession";
            const showNavList = isUser ? showNavUserList : showNavSubList;
            const isLoading =
              isUser && userLoadingBtn.includes(currentStep)
                ? true
                : !isUser && subUserLoadingBtn.includes(currentStep)
                ? true
                : false;
            return (
              <LoadingButton
                color={
                  isUser && currentStep === 11
                    ? "success"
                    : !isUser && currentStep === 9
                    ? "success"
                    : "primary"
                }
                loading={isLoading}
                variant="contained"
                sx={{
                  display:
                    showTourNav || showNavList.includes(currentStep)
                      ? "flex"
                      : "none",
                }}
                onClick={() => {
                  if (isUser && currentStep === 11) {
                    setCurrentStep((s) => 30);
                  } else if (!isUser && currentStep === 9) {
                    setCurrentStep((s) => 20);
                  } else {
                    if (!showTourNav && !showNavList.includes(currentStep)) {
                      return;
                    } else if (last) {
                      setIsOpen(false);
                      navigate("/dashboard");
                    } else {
                      setCurrentStep((s) =>
                        s === steps?.length - 1 ? 0 : s + 1
                      );
                    }
                  }
                }}
                endIcon={<ArrowForwardIcon />}
              >
                {isUser && currentStep === 11
                  ? "Create Permit Package"
                  : !isUser && currentStep === 9
                  ? "Create Permit Package"
                  : last
                  ? "Finish"
                  : "Next"}
              </LoadingButton>
            );
          }}
          prevButton={({ currentStep, setCurrentStep }) => {
            const forUserPrevList = {
              1: "/dashboard",
              2: "/selectcounties",
              3: "/selectcounties",
              6: "/defaults",
              9: "/contractors",
              12: "/permits/create",
              22: "/permits/my-permits",
              24: "/subusers",
              26: "/subscription",
            };
            const forUserDoubleStepper = {
              9: 2,
              12: 3,
              3: 2,
            };
            const subUserPrevList = {
              1: "/dashboard",
              2: "/selectcounties",
              3: "/selectcounties",
              6: "/defaults",
              10: "/permits/create",
            };
            const subUserDoubleStepper = {
              10: 3,
            };
            const userPrevList =
              sessions?.currentSession === "userSession"
                ? forUserPrevList
                : subUserPrevList;
            const userDoubleStepper =
              sessions?.currentSession === "userSession"
                ? forUserDoubleStepper
                : subUserDoubleStepper;

            return (
              <IconButton
                disabled={
                  (sessions?.currentSession === "userSession" &&
                    disableUserPrev.includes(currentStep)) ||
                  (sessions?.currentSession === "subUserSession" &&
                    disableSubUserPrev.includes(currentStep))
                }
                sx={{
                  visibility:
                    (sessions?.currentSession === "userSession" &&
                      disableUserPrev.includes(currentStep)) ||
                    (sessions?.currentSession === "subUserSession" &&
                      disableSubUserPrev.includes(currentStep))
                      ? "hidden"
                      : "visible",
                }}
                onClick={() => {
                  if (
                    currentStep in userPrevList &&
                    location.pathname !== userPrevList[currentStep]
                  ) {
                    navigate(userPrevList[currentStep]);
                    if (currentStep in userDoubleStepper) {
                      setTimeout(() => {
                        setCurrentStep(
                          (s) => s - userDoubleStepper[currentStep]
                        );
                      }, 200);
                    } else {
                      // if (skipSteps.includes(currentStep)) {
                      //   setTimeout(() => {
                      //     setCurrentStep((s) => s - 1);
                      //   }, 1000);
                      // } else {
                      setTimeout(() => {
                        setCurrentStep((s) => s - 1);
                      }, 200);
                      // }
                    }
                  } else {
                    if (currentStep in userDoubleStepper) {
                      setCurrentStep((s) => s - userDoubleStepper[currentStep]);
                    } else {
                      setCurrentStep((s) => s - 1);
                    }
                  }
                }}
              >
                <ArrowBack />
              </IconButton>
            );
          }}
          onClickMask={({ setIsOpen }) => {}}
          styles={{
            popover: (base) => ({
              ...base,
              "--reactour-accent": "#ef5a3d",
              borderRadius: 20,
            }),
            badge: (base) => ({ ...base, left: "auto", right: "auto" }),
            maskArea: (base) => ({ ...base, rx: 20 }),
          }}
        >
          {/* Render your main components with TourProvider */}
          {renderMainComponents()}
        </TourProvider>
      ) : (
        // Render your main components without TourProvider while waiting
        renderMainComponents()
      )}
    </LocalizationProvider>
  );
};

export default App;
