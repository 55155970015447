import {
  Alert,
  Box,
  Button,
  Snackbar,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { green } from "@mui/material/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useEffect, useState } from "react";
import StartNotarizationModal from "../../modals/StartNotarizationModal";
import ReviewCreatedFormsModal from "../../modals/ReviewCreatedFormsModal";
import ContractorSignerTab from "./ContractorSignerTab";
import OwnerSignerTab from "./OwnerSignerTab";
import ScheduleContractorRonModal from "../../modals/ScheduleContractorRonModal";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { getPermitPackages } from "../../../store/actions/dataActions";
import AddPaymentCardModal from "../../modals/AddPaymentCardModal";

const NotarizationTab = (props) => {
  const currentSession = useSelector((state) => state.sessions.currentSession);

  const row = props?.selectedRow;
  const dispatch = useDispatch();
  const { processing_permit } = row;
  const stripe = useStripe();
  const elements = useElements();
  const [notarizeModalOpen, setNotarizeModalOpen] = useState(false);
  const [reviewDocModalOpen, setReviewDocModalOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [signerValue, setSignerValue] = useState(0);
  const [isFading, setIsFading] = useState(false);
  const [ronScheduleOpen, setRonScheduleOpen] = useState(false);
  const [ronStarted, setRonStarted] = useState(false);
  const [fiveMinBeforeSession, setFiveMinBeforeSession] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);
  const [addPaymentModalOpen, setAddPaymentModalOpen] = useState(false);

  const lg = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const snackHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  useEffect(() => {
    if (
      props?.selectedRow?.noc_created &&
      !props?.selectedRow?.permit_package_created
    ) {
      props?.setDocNotaryTypeValue(1);
    } else if (props?.selectedRow?.permit_package_created) {
      props?.setDocNotaryTypeValue(0);
    }
  }, [props?.selectedRow]);

  const handleSignerChange = (event, newValue) => {
    setIsFading(true);
    setTimeout(() => {
      setSignerValue(newValue);
      setIsFading(false);
    }, 300); // Duration should match the CSS transition duration
  };

  const handleAssignToContractor = async () => {
    try {
      setAssignLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/building/assign-permit-package-to-contractor`,
        {
          task_id: props?.selectedRow?.task_id,
          currentSession: currentSession,
        },
        { withCredentials: true }
      );

      if (resp.data.success) {
        const result = await dispatch(
          getPermitPackages(0, props?.pageSize, props?.activeTab)
        );
        props?.setSelectedRow(null);
        props?.setPage(0);
        props?.setRowCount(result);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setAssignLoading(false);
    }
  };

  const handleDocNotaryTypeChange = (event, newValue) => {
    setIsFading(true);
    setTimeout(() => {
      props?.setDocNotaryTypeValue(newValue);
      setIsFading(false);
    }, 300); // Duration should match the CSS transition duration
  };

  useEffect(() => {
    if (
      processing_permit?.notarization?.signing_order === "contractor" &&
      (processing_permit?.notarization?.contractor?.notarization?.is_signed ===
        true ||
        processing_permit?.notarization?.contractor?.notarization
          ?.is_notarized === true)
    ) {
      if (row?.selectedDocuments?.length === 1) {
        setSignerValue(0);
      } else {
        setSignerValue(1);
      }
    } else if (
      processing_permit?.notarization?.signing_order === "owner" &&
      processing_permit?.notarization?.owner?.notarization?.is_notarized ===
        true
    ) {
      if (row?.selectedDocuments?.length === 1) {
        setSignerValue(0);
      } else {
        setSignerValue(1);
      }
    } else {
      setSignerValue(0);
    }
  }, [props?.selectedRow]);

  useEffect(() => {
    if (ronStarted) {
      const timeOut = setTimeout(() => {
        setRonStarted(false);
      }, 15000);
      return () => clearTimeout(timeOut);
    }
  }, [ronStarted]);

  const getNotaryTypeTabComponent = () => {
    switch (props?.docNotaryTypeValue) {
      case 0:
      case 1:
      case 2:
        return (
          <>
            <Typography
              align="center"
              variant="h5"
              mt={1}
              sx={{ color: "#388e3c" }}
            >
              Notarize{" "}
              {props?.docNotaryTypeValue === 0
                ? "All Documents"
                : props?.docNotaryTypeValue === 1
                ? "NOC Only"
                : "Permit Forms"}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <Button
                disabled={
                  row?.city_scraper?.is_accepted &&
                  !row?.city_scraper?.first_done
                }
                onClick={() => {
                  setNotarizeModalOpen(true);
                }}
                variant="contained"
              >
                Get Started
              </Button>
            </Box>
          </>
        );

      default:
        return <></>;
    }
  };

  const getSignerTabComponent = () => {
    switch (signerValue) {
      case 0:
        return processing_permit?.notarization?.signing_order ===
          "contractor" ? (
          <ContractorSignerTab
            {...props}
            setRonScheduleOpen={setRonScheduleOpen}
            fiveMinBeforeSession={fiveMinBeforeSession}
            setFiveMinBeforeSession={setFiveMinBeforeSession}
          />
        ) : (
          <OwnerSignerTab {...props} processing_permit={processing_permit} />
        );
      case 1:
        return processing_permit?.notarization?.signing_order ===
          "contractor" ? (
          <OwnerSignerTab {...props} processing_permit={processing_permit} />
        ) : Object.keys(processing_permit?.notarization?.contractor).length ===
          0 ? (
          <></>
        ) : (
          <ContractorSignerTab
            {...props}
            setRonScheduleOpen={setRonScheduleOpen}
            fiveMinBeforeSession={fiveMinBeforeSession}
            setFiveMinBeforeSession={setFiveMinBeforeSession}
          />
        );

      default:
        return (
          <ContractorSignerTab
            {...props}
            setRonScheduleOpen={setRonScheduleOpen}
            fiveMinBeforeSession={fiveMinBeforeSession}
            setFiveMinBeforeSession={setFiveMinBeforeSession}
          />
        );
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: 1,
        }}
      >
        <img
          src="/img/website_img/notarize.png"
          style={{
            width: "100%",
            maxWidth: "4rem",
            height: "auto",
            borderRadius: "20px",
          }}
        />

        {!row?.permit_package_created && !row?.noc_created ? (
          //? DEFAULT DISPLAY
          <Box mt={6}>
            <Typography align="center" px={1} variant="h6" color="primary">
              Notarization requests have not yet been initiated
            </Typography>
          </Box>
        ) : //? NOTARIZATION FOR CONTRACTOR OWNER
        !row?.notarized &&
          !processing_permit?.notarization?.started &&
          row?.assigned_package?.need_assign &&
          !row?.assigned_package?.is_assigned ? (
          <>
            <Typography
              align="center"
              px={1}
              variant="h6"
              sx={{ pt: 1 }}
              color="error"
            >
              Only the Selected Contractor Can Initiate the Notarization
            </Typography>
            <Typography
              align="center"
              px={2}
              variant="body2"
              sx={{ pt: 2, fontWeight: "bold" }}
              color="primary"
            >
              You can assign this permit package to the contractor, who will
              continue notarize the documents. Once its been assigned, you will
              not be able to make any changes to the permit package. You can
              track the progress of the permit package in the "Packages Sent to
              Contractors" tab.
            </Typography>
            <Box
              sx={{
                maxWidth: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <LoadingButton
                loading={assignLoading}
                onClick={handleAssignToContractor}
                variant="contained"
                sx={{ mt: 2 }}
              >
                Send to Contractor
              </LoadingButton>
            </Box>
          </>
        ) : //? START NOTARIZATION
        !row?.notarized && !processing_permit?.notarization?.started ? (
          <>
            <Typography
              align="center"
              px={1}
              variant="h6"
              sx={{ pt: 1 }}
              color="primary"
            >
              Select the type of document(s) you would like to notarize
            </Typography>
            <Box
              sx={{
                maxWidth: "100%",
                bgcolor: "background.paper",
              }}
            >
              <Tabs
                value={props?.docNotaryTypeValue}
                onChange={handleDocNotaryTypeChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                aria-label="scrollable auto tabs example"
                TabIndicatorProps={{
                  style: {
                    height: "3px", // Customize the height of the indicator
                    bottom: "10px", // Adjust the position closer to the text
                  },
                }}
              >
                <Tab
                  disabled={
                    props?.selectedRow?.noc_created &&
                    !props?.selectedRow?.permit_package_created
                  }
                  label="All Documents"
                  disableRipple
                />
                <Tab
                  disabled={!props?.selectedRow?.noc_created}
                  label="NOC Only"
                  disableRipple
                />
                <Tab
                  disabled={!props?.selectedRow?.permit_package_created}
                  label="Permit Forms"
                  disableRipple
                />
              </Tabs>
            </Box>
            <Box
              sx={{ width: "100%" }}
              className={
                isFading
                  ? "fade-exit fade-exit-active"
                  : "fade-enter fade-enter-active"
              }
            >
              {getNotaryTypeTabComponent()}
            </Box>
          </>
        ) : !row?.notarized &&
          !processing_permit?.notarization?.finalized &&
          processing_permit?.notarization?.started ? (
          <>
            <Box
              sx={{
                maxWidth: "100%",
                bgcolor: "background.paper",
                // display: "flex",
                // justifyContent: "center",
              }}
            >
              <Tabs
                value={signerValue}
                onChange={handleSignerChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                aria-label="scrollable auto tabs example"
                TabIndicatorProps={{
                  style: {
                    height: "3px", // Customize the height of the indicator
                    bottom: "10px", // Adjust the position closer to the text
                  },
                }}
              >
                <Tab
                  sx={{
                    ml:
                      Object.keys(processing_permit?.notarization?.contractor)
                        .length === 0
                        ? 11
                        : undefined,
                  }}
                  label={
                    processing_permit?.notarization?.signing_order ===
                    "contractor"
                      ? "1. Contractor"
                      : "1. Owner/Agent"
                  }
                  disableRipple
                />

                <Tab
                  sx={{
                    visibility:
                      Object.keys(processing_permit?.notarization?.contractor)
                        .length === 0
                        ? "hidden"
                        : undefined,
                  }}
                  label={
                    processing_permit?.notarization?.signing_order ===
                    "contractor"
                      ? "2. Owner/Agent"
                      : "2. Contractor"
                  }
                  disableRipple
                />
              </Tabs>
            </Box>
            <Box
              sx={{ width: "100%" }}
              className={
                isFading
                  ? "fade-exit fade-exit-active"
                  : "fade-enter fade-enter-active"
              }
            >
              {getSignerTabComponent()}
            </Box>
            {/* <Box
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
              }}
            >
              <Button
                onClick={handleSyncData}
                disableRipple
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                endIcon={<RefreshIcon className={fetching ? "rotate" : ""} />}
              >
                Sync Latest Data
              </Button>
            </Box> */}
          </>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography align="center" variant="h5" color="primary" mt={2}>
                Notarized Permit Package
              </Typography>
              <CheckCircleIcon
                style={{
                  color: green[500],
                  marginLeft: 8,
                  marginTop: 15,
                  fontSize: "30px",
                }}
              />
            </Box>

            <Typography
              variant="body2"
              fontWeight="bold"
              color="primary"
              align="center"
              px={2}
              mt={2}
            >
              All of your documents have been notarized successfully. The
              notarized documents can be downloaded by clicking on the download
              button for each file.
            </Typography>
          </>
        )}
      </Box>
      <StartNotarizationModal
        open={notarizeModalOpen}
        setOpen={setNotarizeModalOpen}
        selectedRow={props?.selectedRow}
        paymentCards={props?.paymentCards}
        setReviewDocModalOpen={setReviewDocModalOpen}
        setSnackOpen={setSnackOpen}
        setRonScheduleOpen={setRonScheduleOpen}
        contractorSigning={props?.contractorSigning}
        ownerSigning={props?.ownerSigning}
        docNotaryTypeValue={props?.docNotaryTypeValue}
        setAddPaymentModalOpen={setAddPaymentModalOpen}
      />
      <ReviewCreatedFormsModal
        open={reviewDocModalOpen}
        setOpen={setReviewDocModalOpen}
        selectedRow={props?.selectedRow}
        docNotaryTypeValue={props?.docNotaryTypeValue}
      />
      <ScheduleContractorRonModal
        open={ronScheduleOpen}
        setOpen={setRonScheduleOpen}
        selectedRow={props?.selectedRow}
        setRonStarted={setRonStarted}
        setSnackOpen={setSnackOpen}
        fiveMinBeforeSession={fiveMinBeforeSession}
      />
      <AddPaymentCardModal
        addPaymentModalOpen={addPaymentModalOpen}
        setAddPaymentModalOpen={setAddPaymentModalOpen}
        stripe={stripe}
        elements={elements}
        CardElement={CardElement}
      />
      <Snackbar
        sx={{ zIndex: 9999999 }}
        open={snackOpen}
        autoHideDuration={4000}
        onClose={snackHandleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={snackHandleClose}
          severity="success" // Changed severity to success
          sx={{
            width: "100%",
            marginTop: "80px",
            marginLeft: lg ? "280px" : "0px",
            backgroundColor: "#388e3c", // Green shade for success
            color: "#fff",
            fontWeight: "bold",
          }}
          iconMapping={{
            success: <CheckCircleIcon sx={{ color: "#fff" }} />, // Changed to success icon
          }}
        >
          {ronStarted
            ? "RON Session has been scheduled successfully!"
            : "Payment Successful. Thank you"}
        </Alert>
      </Snackbar>
    </>
  );
};

export default NotarizationTab;
