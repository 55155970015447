import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchGuestData } from "../../store/actions/guestActions";
import { DateTime } from "luxon";
import TimezoneSelect from "../layout/TimezoneSelect";
import { timezoneMappings } from "../../data/timezones";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Button,
} from "@mui/material";
import ChangeRonComponent from "../permitComponents/contractor/ChangeRonComponent";
import { LoadingButton } from "@mui/lab";

const ManageSigneeAppointment = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scrollableElementRef = useRef(null);
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const guestUser = useSelector((state) => state.guestSession);
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const largeTabletSize = useMediaQuery(theme.breakpoints.down("lg"));

  const [pageLoading, setPageLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [isAvailable, setIsAvailable] = useState(false);
  const [appointment, setAppointment] = useState("");
  const [timezone, setTimezone] = useState("America/New_York");
  const [slots, setSlots] = useState([]);
  const [unavailableText, setUnavailableText] = useState("");
  const [commonText, setCommonText] = useState("");
  const [notaryFinished, setNotaryFinished] = useState(false);
  const [ids, setIds] = useState();
  const [availableDate, setAvailableDate] = useState(null);
  const [checkDateLoading, setCheckDateLoading] = useState(false);
  const [changeAvailable, setChangeAvailable] = useState(true);
  const [bookedISO, setBookedISO] = useState("");
  const [sessionReady, setSessionReady] = useState(false);
  const [open, setOpen] = useState(false);
  const [mustReschedule, setMustReschedule] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const [uiTexts, setUiTexts] = useState({
    title: "Remote Online Notarization (RON) Appointment",
    description:
      "We have scheduled a notarization session for you. You can join the session through the button provided on our platform 5 minutes before the scheduled time. Before joining the session, you will need to complete the identity verification process. This includes biometric face authentication using your mobile phone and validating your ID card. Once both steps are completed, you will be redirected to the signing session to meet your notary. Please note that there is a 15-minute grace period after the scheduled time, after which the notarization session will no longer be available, and a new appointment will need to be scheduled.",
    idWarn:
      "In the meantime, make sure you have your identification documents ready.",
    sessionStartWarn: "",
    rescheduleWarn: "",
  });
  console.log(guestUser);

  useEffect(() => {
    const handleGetData = async () => {
      await dispatch(fetchGuestData("guestSession"));
    };

    if (!guestUser?.manageData) {
      handleGetData();
    }
  }, []);

  useEffect(() => {
    if (guestUser?.manageData) {
      const preBookedISO =
        guestUser?.manageData?.owner?.notarization?.appointments?.bookedDateISO;
      // if (preBookedISO && preBookedISO !== bookedISO) {
      setBookedISO(preBookedISO);

      const appointmentTimeUTC = DateTime.fromISO(preBookedISO, {
        zone: "UTC",
      });

      console.log(preBookedISO);
      const currentTimeUTC = DateTime.now().setZone("UTC");
      const difference = appointmentTimeUTC.diff(
        currentTimeUTC,
        "minutes"
      ).minutes;

      console.log("difference", difference);

      if (Math.abs(difference) <= 10) {
        setSessionReady(true);
      } else {
        setSessionReady(false);
      }

      if (Math.abs(difference) > 60) {
        setChangeAvailable(true);
      } else {
        setChangeAvailable(false);
      }
      // }
    }
  }, [guestUser?.manageData?.owner?.notarization?.appointments?.bookedDateISO]);

  useEffect(() => {
    if (bookedISO) {
      const appointmentTimeUTC = DateTime.fromISO(bookedISO, {
        zone: "UTC",
      });

      const currentTime = appointmentTimeUTC.setZone(timezone);

      setAppointment(
        `Your session is scheduled for ${currentTime.toFormat(
          "cccc, LLLL dd, yyyy hh:mm a"
        )}`
      );
    }
  }, [timezone, bookedISO]);

  useEffect(() => {
    if (guestUser?.manageData) {
      setPropertyDetails(guestUser?.manageData?.overview_data);
      if (guestUser?.manageData?.notary_finished) {
        setNotaryFinished(true);
        setUiTexts((prev) => ({
          ...prev,
          title:
            "Remote Online Notarization (RON) Appointment Has Been Completed",
          description:
            "We are pleased to inform you that your document has been notarized successfully. The recording process for the notarized document has already begun. The document has been sent to the recording office for recording. Please wait while they process your document. It may take up to five days for this process to be completed.",
          sessionStartWarn: "Notarization completed at:",
        }));
      }
    }
    setPageLoading(false);
  }, [guestUser, dispatch]);

  useEffect(() => {
    if (guestUser?.notaryRecordCompleted) {
      setNotaryFinished(true);
      const dateTime = DateTime.fromISO(guestUser?.notaryRecordCompletedTime, {
        zone: timezone,
      });
      setAppointment(dateTime.toFormat("cccc, LLLL dd, yyyy hh:mm a"));
    }
  }, [guestUser, timezone]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      scheduledDate: null,
    },
    validationSchema: Yup.object({
      scheduledDate: Yup.string().required("Date is required"),
    }),
    onSubmit: async (values) => {
      setSubmitLoading(true);
      try {
        const payload = {
          task_id: ids?.task_id,
          noc_id: ids?.noc_id,
          currentSession: currentSession,
          timezone: timezone,
        };

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/signee/finalize-change-appointment`,
          { payload },
          { withCredentials: true }
        );
        if (response.data.success) {
          const dateTime = DateTime.fromISO(response?.data?.newDate, {
            zone: timezone,
          });
          setAppointment(
            `Your session is scheduled for ${dateTime.toFormat(
              "cccc, LLLL dd, yyyy hh:mm a"
            )}`
          );
          setCommonText(response.data.message);
        }
      } catch (e) {
        console.error("Error finalizing appointment:", e);
      }
      setSubmitLoading(false);
    },
  });

  useEffect(() => {
    if (
      guestUser?.manageData?.owner?.notarization?.appointments?.booked
        ?.length === 0 &&
      !guestUser?.manageData?.owner?.notarization?.is_notarized
    ) {
      console.log("called");
      setMustReschedule(true);
      setUiTexts((prev) => ({
        ...prev,
        description:
          "The notarization session has expired and is no longer available. To proceed, please reschedule a new Remote Online Notarization (RON) session at a time that suits you. You can select a new appointment time directly on our platform. Before joining the rescheduled session, remember to complete the identity verification process, which includes biometric face authentication on your mobile device and validating your ID card. Once these steps are done, you’ll be directed to the signing session to meet with your notary. Please have your identification documents ready, and note that the rescheduled session will also allow a 15-minute grace period after the scheduled time. If you miss this window, you will need to reschedule once more.",
        idWarn: "Thank you for your understanding and cooperation!",
      }));
    } else if (
      guestUser?.manageData?.owner?.notarization?.appointments?.booked
        ?.length === 0 &&
      guestUser?.manageData?.owner?.notarization?.is_notarized
    ) {
      setMustReschedule(false);
      setUiTexts((prev) => ({
        ...prev,
        description:
          "Your notarization session has been successfully completed. Thank you for completing the identity verification process and meeting with your notary. The session details and completed documents are now available on our platform for your records.",
        title: "Remote Online Notarization (RON) Completed",
        idWarn: "",
      }));
      setAppointment("");
    } else {
      setMustReschedule(false);
      setUiTexts((prev) => ({
        ...prev,
        description:
          "We have scheduled a notarization session for you. You can join the session through the button provided on our platform 5 minutes before the scheduled time. Before joining the session, you will need to complete the identity verification process. This includes biometric face authentication using your mobile phone and validating your ID card. Once both steps are completed, you will be redirected to the signing session to meet your notary. Please note that there is a 15-minute grace period after the scheduled time, after which the notarization session will no longer be available, and a new appointment will need to be scheduled.",
        idWarn:
          "In the meantime, make sure you have your identification documents ready.",
      }));
    }
  }, [guestUser?.manageData?.owner?.notarization]);

  const handleDownloadNoc = async () => {
    try {
      setDownloadLoading(true);
      const currentDate = DateTime.now().toFormat("yyyy-MM-dd");
      let pdfName;

      pdfName = `Notice_of_Commencement_owner_signed-${currentDate}.pdf`;

      // Perform the download request
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/download-noc-guest`,
        {
          task_id: guestUser?.manageData?.task_id,
          currentSession: currentSession,
        },
        {
          withCredentials: true,
          responseType: "blob",
        }
      );

      // Create a URL and initiate download
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", pdfName);
      document.body.appendChild(fileLink);
      fileLink.click();

      // Clean up
      document.body.removeChild(fileLink);
      window.URL.revokeObjectURL(fileURL);
    } catch (e) {
      console.error("Download error:", e);
    } finally {
      setDownloadLoading(false);
    }
  };

  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <Box
          sx={
            tabletSize
              ? {
                  justifyContent: "center",
                  overflowX: "hidden",
                  pl: 1,
                  minHeight: "100vh",
                  overflowY: "hidden",
                  display: "flex",
                  flexDirection: "column",
                }
              : {
                  backgroundImage: "url('/img/website_img/homeImg.jpeg')",
                  backgroundRepeat: "repeat",

                  minHeight: "100vh",
                  overflow: "hidden",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }
          }
        >
          <Box
            sx={
              tabletSize
                ? {
                    backgroundColor: "#fff",
                    py: "1rem",
                    maxWidth: "100%",
                    flexGrow: 1,
                  }
                : {
                    backgroundColor: "#fff",
                    my: 2,
                    maxWidth: "80%",
                    borderRadius: "25px",
                    px: 3,
                    width: "80%",
                    py: "2rem",
                    position: "relative",
                  }
            }
          >
            {/* <div
              style={
                mobileSize
                  ? { width: "100%", padding: "1rem", position: "relative" }
                  : { paddingBottom: "1rem", position: "relative" }
              }
            > */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
              // onClick={() => {
              //   navigate("/");
              // }}
            >
              <img
                style={{
                  width: tabletSize ? "15rem" : "10rem",
                }}
                alt="Permit Rockstar Logo"
                src="/img/website_img/PermitRockstar.png"
              />
            </Box>
            {/* Top Fixed Text */}
            <Box
              sx={{
                position: "absolute",
                top: tabletSize ? 27 : 48,
                right: tabletSize ? 10 : 20,
              }}
            >
              {!mobileSize && !notaryFinished && (
                <TimezoneSelect
                  specialDisabled2={
                    formik.values.scheduledDate ||
                    slots.length !== 0 ||
                    unavailableText !== "" ||
                    checkDateLoading
                  }
                  options={timezoneMappings}
                  timezone={timezone}
                  setTimezone={setTimezone}
                  specificWidth={tabletSize ? "11rem" : undefined}
                  changeLocal={true}
                />
              )}
            </Box>
            <Typography
              color={notaryFinished ? "#4CAF50" : "primary"}
              variant={mobileSize ? "h5" : "h4"}
              sx={{ mb: mobileSize ? 1 : 2, mt: 1 }}
              align="center"
            >
              {uiTexts?.title}
            </Typography>
            {notaryFinished && (
              <Typography
                color={"primary"}
                variant={mobileSize ? "h5" : "h4"}
                sx={{ mb: mobileSize ? 1 : 2, mt: 1 }}
                align="center"
              >
                The Document Recording Process Has Been Initiated
              </Typography>
            )}

            <Typography
              color="primary"
              variant={mobileSize ? "body2" : "body1"}
              align="center"
              sx={{ mb: tabletSize && !mobileSize ? 0 : 0 }}
            >
              {uiTexts?.description}
            </Typography>

            <Typography
              color="#FF9800"
              variant="body1"
              align="center"
              fontWeight="bold"
              sx={{ mb: tabletSize && !mobileSize ? 5 : mobileSize ? 0.5 : 5 }}
            >
              {uiTexts?.idWarn}
            </Typography>
            {mobileSize && !notaryFinished && (
              <Box sx={{ display: "flex", justifyContent: "center", mb: 5 }}>
                <TimezoneSelect
                  specialDisabled2={
                    formik.values.scheduledDate ||
                    slots.length !== 0 ||
                    unavailableText !== "" ||
                    checkDateLoading
                  }
                  options={timezoneMappings}
                  timezone={timezone}
                  setTimezone={setTimezone}
                  specificWidth={largeTabletSize ? "15rem" : undefined}
                  changeLocal={true}
                />
              </Box>
            )}

            {/* IMMEDIATE */}
            {propertyDetails?.instant ? (
              <>
                <Typography
                  variant={mobileSize ? "h6" : "h5"}
                  align="center"
                  sx={{ mb: 1 }}
                  color="#F57C00"
                >
                  {uiTexts?.sessionStartWarn}
                </Typography>
                <Typography
                  mb={guestUser?.notaryRecordCompleted ? 2 : undefined}
                  variant={mobileSize ? "h5" : "h4"}
                  align="center"
                  color="#4CAF50"
                >
                  {appointment}
                </Typography>
                <Box align="center" mb={2}>
                  {sessionReady ? (
                    <Button
                      onClick={() => {
                        window.open(
                          guestUser?.manageData?.owner?.notarization?.bn_session
                            ?.signer_url,
                          "_blank"
                        );
                      }}
                      size="large"
                      variant="contained"
                    >
                      Join Session
                    </Button>
                  ) : !guestUser?.manageData?.owner?.notarization
                      ?.is_notarized ? (
                    <Typography variant="h6" px={4} color="primary">
                      You can join the session from this page starting 5 minutes
                      before the scheduled time. Please be ready a few minutes
                      early to ensure a smooth start.
                    </Typography>
                  ) : (
                    <Typography variant="h6" px={4} sx={{ color: "#10b981" }}>
                      Remote Online Notarization has been Successfully
                      Completed!
                    </Typography>
                  )}
                </Box>
                {!guestUser?.notaryRecordCompleted && (
                  <Typography
                    variant="h5"
                    align="center"
                    color="#4CAF50"
                    sx={{ mb: "2rem" }}
                  >
                    Please complete the identity verification before this time
                    by following the instructions that were sent to your email:{" "}
                    {guestUser?.manageData?.owner?.personal?.email}
                  </Typography>
                )}

                <Grid
                  container
                  spacing={tabletSize ? 1 : 0}
                  align="center"
                  mb={tabletSize ? 2 : 5}
                >
                  <div
                    style={{
                      height: "4px",
                      backgroundColor: "#f3f3f3",
                      width: "100%",
                      marginBottom: "1rem",
                    }}
                  ></div>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Property Address</Typography>
                    <Typography variant="body2">
                      {
                        guestUser?.manageData?.overview_data
                          ?.affected_property[0]?.value
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Contractor</Typography>
                    <Typography variant="body2">
                      {guestUser?.manageData?.contractor?.businessName}
                    </Typography>
                    <Typography variant="body2">
                      {guestUser?.manageData?.contractor?.mobile}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography variant="h6" mb={0.5} color="error">
                  {uiTexts?.rescheduleWarn}
                </Typography>
              </>
            ) : (
              <>
                <form noValidate onSubmit={formik.handleSubmit}>
                  <Box
                    ref={scrollableElementRef}
                    sx={{
                      width: "100%",
                      mt: "2rem",
                      mb: "1rem",
                    }}
                  >
                    <Typography
                      variant={mobileSize ? "h6" : "h5"}
                      align="center"
                      sx={{ mb: 1 }}
                      color="#F57C00"
                    >
                      {uiTexts?.sessionStartWarn}
                    </Typography>
                    {!mustReschedule && (
                      <Typography
                        sx={{ mb: "2rem" }}
                        variant={mobileSize ? "h5" : "h4"}
                        align="center"
                        color="#4CAF50"
                      >
                        {appointment}
                      </Typography>
                    )}

                    <Box align="center" mb={2}>
                      {sessionReady ? (
                        <Button
                          onClick={() => {
                            window.open(
                              guestUser?.manageData?.owner?.notarization
                                ?.bn_session?.signer_url,
                              "_blank"
                            );
                          }}
                          size="large"
                          variant="contained"
                        >
                          Join Session
                        </Button>
                      ) : (
                        <Typography
                          variant="h6"
                          px={4}
                          sx={{
                            color: mustReschedule
                              ? "#f04438"
                              : !guestUser?.manageData?.owner?.notarization
                                  ?.is_notarized
                              ? "#004976"
                              : "#10b981",
                          }}
                        >
                          {mustReschedule
                            ? "You need to re-schedule your Remote Online Notarization (RON) session."
                            : !guestUser?.manageData?.owner?.notarization
                                ?.is_notarized
                            ? "You can join the session from this page starting 5 minutes before the scheduled time. Please be ready a few minutes early to ensure a smooth start."
                            : "Remote Online Notarization has been Successfully Completed!"}
                        </Typography>
                      )}
                    </Box>
                    {guestUser?.manageData?.owner?.notarization
                      ?.is_notarized && (
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <LoadingButton
                          loading={downloadLoading}
                          onClick={handleDownloadNoc}
                          size="large"
                          variant="contained"
                          color="success"
                        >
                          Download Notarized Document
                        </LoadingButton>
                      </Box>
                    )}
                    <Grid
                      container
                      align="center"
                      mb={5}
                      mt={
                        guestUser?.manageData?.owner?.notarization?.is_notarized
                          ? 3
                          : undefined
                      }
                    >
                      <div
                        style={{
                          height: "4px",
                          backgroundColor: "#f3f3f3",
                          width: "100%",
                          marginBottom: "1rem",
                        }}
                      ></div>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Property Address</Typography>
                        <Typography variant="body2">
                          {
                            guestUser?.manageData?.overview_data
                              ?.affected_property[0]?.value
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Contractor</Typography>
                        <Typography variant="body2">
                          {guestUser?.manageData?.contractor?.businessName}
                        </Typography>
                        <Typography variant="body2">
                          {guestUser?.manageData?.contractor?.mobile}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography
                      variant={commonText === "" ? "h6" : "h4"}
                      mb={commonText === "" ? 2 : 1}
                      color={commonText === "" ? "error" : "primary"}
                      align={commonText === "" ? "left" : "center"}
                    >
                      {commonText === "" ? uiTexts?.rescheduleWarn : commonText}
                    </Typography>
                    {changeAvailable === true || mustReschedule ? (
                      <Button variant="contained" onClick={() => setOpen(true)}>
                        Change Appointment
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Box>
                </form>
              </>
            )}
            {/* </div> */}
          </Box>
          <Box>
            <ChangeRonComponent
              open={open}
              setOpen={setOpen}
              taskId={guestUser?.manageData?.task_id}
              user="owner"
              userZone={timezone}
              setBookedISO={setBookedISO}
              setMustReschedule={setMustReschedule}
              setUiTexts={setUiTexts}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default ManageSigneeAppointment;
