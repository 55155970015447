import { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Tooltip,
  Box,
  Typography,
} from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import PersonIcon from "@mui/icons-material/Person";
import NotaryInvoiceModal from "./popover/datagridRowPopover/NotaryInvoiceModal";
import { useTour } from "@reactour/tour";

const NotaryInvoiceGrid = ({
  title,
  notaryInvoiceRow,
  tabletSize,
  mobileSize,
  iphone12Height,
  smallHeight,
}) => {
  const apiRef = useGridApiRef();
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [rowIndex, setRowIndex] = useState(0);
  const [rowOpen, setRowOpen] = useState(false);
  const { isOpen } = useTour();

  const handleOpen = (params, index) => {
    if (!tabletSize) {
      return;
    }
    setSelectedRow(params.row);
    setRowData(params.row);
    setRowIndex(index);
    setRowOpen(true);
  };

  const handleClose = () => {
    setRowOpen(false);
    const currentPage = Math.floor(rowIndex / 5);
    if (apiRef.current) {
      apiRef.current.setPage(currentPage);
    }
  };

  const handleSwipe = (index) => {
    setRowIndex(index);
    setRowData(notaryInvoiceRow[index]);
    setSelectedRow(notaryInvoiceRow[index]);
  };

  useEffect(() => {
    if (!tabletSize && rowData) {
      setRowData(false);
    }
  }, [tabletSize, rowData]);

  const invoiceColumnsSmall = [
    {
      field: "reason",
      headerName: title,
      flex: 1,
      editable: false,
      renderCell: (params) => {
        const formattedDate = new Date(
          params.row.created_at
        ).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });

        return (
          <>
            <Box sx={{ display: "flex", width: "100%", pr: 2 }}>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  // borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                  pb: 0.2,
                  pl: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  {formattedDate}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "0.85rem" }}>
                  {params.value}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                >
                  {params.row?.price}
                </Typography>
              </Box>
            </Box>
          </>
        );
      },
    },
  ];

  const invoiceColumns = [
    {
      field: "created_at",
      headerName: "Date",
      width: 100,
      editable: false,
    },
    {
      field: "reason",
      headerName: "Billing Reason",
      flex: 1,
      editable: false,
    },
    {
      field: "user",
      headerName: "Initiated",
      flex: 1,
      editable: false,
    },
    {
      field: "property",
      headerName: "Property Address",
      flex: 1,
      editable: false,
    },
    {
      field: "source",
      headerName: "Document Source",
      flex: 0.5,
      editable: false,
    },
    {
      field: "payment_type",
      headerName: "Payment Type",
      width: 120,
      editable: false,
    },
    {
      field: "price",
      headerName: "Price",
      width: 110,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 110,
      editable: false,
    },
    {
      field: "isSubuser",
      headerName: "",
      width: 50,
      editable: false,
      renderCell: (params) => {
        // Check if the current row's isSubuser value is true
        return params.value === true ? (
          <Tooltip title="Subuser initiated this transaction ">
            <PersonIcon sx={{ color: "#004976" }} />
          </Tooltip>
        ) : (
          ""
        );
      },
    },
  ];

  return (
    <>
      <Card className="step29">
        {!tabletSize && (
          <CardHeader
            title={title}
            sx={{ textAlign: isOpen ? "center" : "left" }}
          />
        )}
        <CardContent
          sx={{
            p: tabletSize ? 0 : undefined,
          }}
        >
          {notaryInvoiceRow.length === 0 ? (
            <Typography color="primary" variant="h6" mb={2} align="center">
              You have no invoices to display just yet
            </Typography>
          ) : (
            <DataGrid
              apiRef={apiRef}
              sx={{
                "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
                "& .MuiDataGrid-columnHeader:focus-within": {
                  outline: "none !important",
                },
                "& .MuiDataGrid-cell": {
                  borderColor: tabletSize ? "rgba(0, 0, 0, 0.1)" : undefined, // Change cell border color
                  p: tabletSize ? 0 : undefined,
                },
                "& .MuiDataGrid-columnHeader": tabletSize
                  ? {
                      backgroundColor: "#333333", // Change column header background color
                      color: "white",
                      fontSize: "15px",
                      paddingLeft: 1,
                      paddingRight: 2,
                      margin: 0,
                      borderTopRightRadius: 0,
                      borderTopLeftRadius: 0,
                    }
                  : {},
                "& .MuiDataGrid-columnHeaders": {
                  padding: 0,
                  margin: 0,
                  // borderTopRightRadius: 0,
                  // borderTopLeftRadius: 0,
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
              }}
              rows={notaryInvoiceRow}
              columns={tabletSize ? invoiceColumnsSmall : invoiceColumns}
              getRowId={(row) => row?.id}
              rowHeight={tabletSize ? 75 : undefined}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25, 50, 100]}
              disableRowSelectionOnClick
              onRowClick={(params, event, details) =>
                handleOpen(params, notaryInvoiceRow.indexOf(params.row))
              }
              disableColumnFilter={tabletSize}
            />
          )}
        </CardContent>
      </Card>
      {rowData && (
        <NotaryInvoiceModal
          mobileSize={mobileSize}
          tabletSize={tabletSize}
          rowOpen={rowOpen}
          selectedRow={selectedRow}
          rowIndex={rowIndex}
          handleClose={handleClose}
          notaryInvoiceRow={notaryInvoiceRow}
          handleSwipe={handleSwipe}
          iphone12Height={iphone12Height}
          smallHeight={smallHeight}
        />
      )}
    </>
  );
};

export default NotaryInvoiceGrid;
