import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DateTime } from "luxon";
import ScheduleRONComponent from "../permitComponents/contractor/ScheduleRONComponent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { updatePermitPackage } from "../../store/actions/dataActions";
import { useNavigate } from "react-router-dom";
import { convertTimezoneFromObj } from "../../functions/helperFunctions";
import { updateSignerRonDate } from "../../store/actions/guestActions";

const ScheduleContractorRonModal = (props) => {
  const navigate = useNavigate();
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession]);
  const dispatch = useDispatch();
  const [userTimezone, setUserTimeZone] = useState("America/New_York");
  const [scheduledDate, setScheduledDate] = useState(null);
  const [isScheduled, setIsScheduled] = useState(false);
  const [ronDate, setRonDate] = useState("");
  const [sessionUrl, setSessionUrl] = useState(false);
  const [onlySign, setOnlySign] = useState(false);
  const base = {
    instantError: "",
    isInstantAvailable: false,
    checkInstantLoading: false,
    expeditedSelectedBtn: false,
    furtherSelectedBtn: false,
    yesLoading: false,
    instantData: {},
    furtherData: {},
    instantTimeLeft: 60,
    startInstantTimer: false,
    immediateRequested: false,
    instantAccepted: false,
    canceledInstant: false,
    immediateLock: false,
    submitting: false,
    slots: [],
    checkDateLoading: false,
    startTimer: false,
    furtherRequested: false,
    scheduleTimeLeft: 60,
    isAvailable: false,
    furtherError: "",
    tempDate: null,
    tempTime: null,
    unavailableText: "",
    furtherDateObj: null,
    isFurtherAvailable: false,
    slotIndex: -1,
    firstTimer: true,
    submitError: "",
  };
  const [schedule, setSchedule] = useState(base);

  useEffect(() => {
    if (
      props?.selectedRow?.processing_permit?.notarization?.contractor
        ?.notarization?.only_sign &&
      onlySign === false
    ) {
      setOnlySign(true);
    } else if (
      !props?.selectedRow?.processing_permit?.notarization?.contractor
        ?.notarization?.only_sign &&
      onlySign === true
    ) {
      setOnlySign(false);
    }
  }, [props?.selectedRow]);

  const dispatchInitialRon = async () => {
    let actionModule;
    if (currentSession === "subUserSession") {
      actionModule = await import("../../store/actions/subUserActions");
    } else {
      actionModule = await import("../../store/actions/userActions");
    }
    dispatch(actionModule.setInstantRonInitialState());
  };

  function convertDayjsToObject(dayjsObj) {
    return {
      day_of_month: dayjsObj.date(), // Get the day of the month
      month: dayjsObj.month() + 1, // Months are zero-indexed, add 1 for 1-12 format
      year: dayjsObj.year(),
      time: dayjsObj.format("HH:mm"), // Formats the time in 'HH:mm' format
    };
  }

  //! FINAL //////////////////////////////////////////////////////
  const startInstantRon = async () => {
    setSchedule((prev) => ({
      ...prev,
      submitError:
        "** Warning ** You should not leave the page or refresh the page while we are assigning you a notary. The process will take less than a minute.",
    }));

    let payload = null;

    if (props?.ownerData) {
      payload = {
        task_id: props?.selectedRow?.task_id,
        signeeEmail: props?.ownerData["email"],
        currentSession: currentSession,
        name: props?.ownerData["name"],
        signatoryTitle: props?.ownerData["signatoryTitle"],
        authority: props?.ownerData["authority"],
        phone: props?.ownerData["phone"],
        ownerAddress: props?.ownerData["ownerAddress"],
        address: props?.ownerData["address"],
        city: props?.ownerData["city"],
        state: props?.ownerData["state"],
        zipCode: props?.ownerData["zipCode"],
        owners: props?.ownerData["owners"],
        dob: convertDayjsToObject(props?.ownerData["dob"]),
        idType: props?.ownerData["idType"],
        timezone: props?.ownerZone,
      };
    } else {
      payload = {
        task_id: props?.selectedRow?.task_id,
        currentSession: currentSession,
        timezone: userTimezone,
      };
    }

    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/notary/start-instant-contractor-ron`,
        {
          payload,
        },
        {
          withCredentials: true,
        }
      );

      if (resp.data.success === true) {
        if (props?.ownerData) {
          // dispatch(updateSignerRonDate(resp?.data?.scheduled_date));
          navigate("/signee/manage");
          return;
        }
        setSchedule((prev) => ({
          ...prev,
          instantError: "",
          startInstantTimer: false,
        }));

        dispatchInitialRon();
        const permit = resp?.data?.data;
        await dispatch(updatePermitPackage(permit));
        // props?.setOpen(false);
        const userZone = currentUser?.subuser
          ? currentUser?.subuser?.timezone
          : currentUser?.user?.personal?.[0]?.timezone;
        const dateString = convertTimezoneFromObj(
          permit?.processing_permit?.notarization?.contractor?.notarization
            ?.appointments?.booked[0],
          userZone || "America/New_York",
          "cccc, LLLL dd, yyyy hh:mm a"
        );
        setRonDate(dateString);
        setSessionUrl(
          permit?.processing_permit?.notarization?.contractor?.notarization
            ?.bn_session?.signer_url
        );
        setIsScheduled(true);
        props?.setRonStarted(true);
        props?.setSnackOpen(true);
        setSchedule(base);
      } else {
        setSchedule((prev) => ({
          ...prev,
          instantAccepted: false,
          immediateLock: false,
          submitError: "",
        }));
        dispatchInitialRon();
      }
    } catch (e) {
      setSchedule((prev) => ({
        ...prev,
        submitError: "",
      }));
      if (e?.response?.data?.error === "stop") {
        setSchedule((prev) => ({
          ...prev,
          slots: [],
          slotIndex: -1,
          isInstantAvailable: false,
          instantAccepted: false,
          checkInstantLoading: false,
          isAvailable: false,
          instantData: {},
          firstTimer: true,
          instantError: e?.response?.data?.message,
          immediateRequested: false,
          canceledInstant: false,
          immediateLock: false,
          startInstantTimer: false,
          instantTimeLeft: 0,
        }));
        dispatchInitialRon();
      } else {
        dispatchInitialRon();
        setSchedule((prev) => ({
          ...prev,
          immediateLock: false,
        }));
      }
    }
  };
  //! //////////////////////////////////////////////////////////////

  //! EXPEDITED RON FUNC
  const handleImmediateRON = async () => {
    if (schedule?.isInstantAvailable) {
      setSchedule((prev) => ({
        ...prev,
        checkInstantLoading: true,
      }));
      try {
        const payload = {
          task_id: props?.selectedRow?.task_id,
          currentSession: currentSession,
          instantData: schedule?.instantData,
          instant: true,
          timezone: userTimezone,
          // contractor: true,
          // failed:
          //   props?.selectedRow?.processing_permit?.notarization?.contractor
          //     ?.notarization?.notary_failed,
        };

        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/notary/request-immediate-ron`,
          {
            payload,
          },
          {
            withCredentials: true,
          }
        );
        if (resp.data.success) {
          setSchedule((prev) => ({
            ...prev,
            instantTimeLeft: 300,
            startInstantTimer: true,
            immediateRequested: true,
          }));
        }
      } catch (e) {}
      setSchedule((prev) => ({
        ...prev,
        checkInstantLoading: false,
      }));
    } else {
      setSchedule((prev) => ({
        ...prev,
        checkInstantLoading: true,
        yesLoading: true,
      }));
      setScheduledDate(null);

      const dateTime = DateTime.now().setZone(userTimezone);

      const formattedObject = {
        day_of_month: dateTime.day,
        month: dateTime.month,
        year: dateTime.year,
        time: dateTime.toFormat("HH:mm"),
      };

      const payload = {
        task_id: props?.selectedRow?.task_id,
        ask_date: formattedObject,
        instant: true,
        timezone: userTimezone,
        currentSession: currentSession,
      };
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/notary/available-ron-schedule`,
          {
            payload,
          },
          {
            withCredentials: true,
          }
        );
        if (resp.data.success) {
          setSchedule((prev) => ({
            ...prev,
            isInstantAvailable: true,
            instantData: resp.data,
            // immediateRequested: true,
            instantTimeLeft: 60,
            startInstantTimer: true,
          }));
        } else {
          setSchedule((prev) => ({
            ...prev,
            instantError: resp.data.message,
          }));
        }
      } catch (e) {
      } finally {
        setSchedule((prev) => ({
          ...prev,
          checkInstantLoading: false,
          yesLoading: false,
        }));
      }
    }
  };
  //! /////////////////////

  //! FURTHER DATE ////////////////////////////////////
  const handleStartClick = async () => {
    if (schedule?.isFurtherAvailable) {
      setSchedule((prev) => ({
        ...prev,
        checkDateLoading: true,
      }));

      try {
        const payload = {
          task_id: props?.selectedRow?.task_id,
          currentSession: currentSession,
          instantData: schedule?.furtherData,
          instant: true,
          timezone: userTimezone,
          further: schedule?.furtherSelectedBtn,
        };
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/notary/request-immediate-ron`,
          {
            payload,
          },
          {
            withCredentials: true,
          }
        );
        if (resp.data.success) {
          setSchedule((prev) => ({
            ...prev,
            scheduleTimeLeft: 300,
            startTimer: true,
            furtherRequested: true,
          }));
        }
      } catch (e) {}
      setSchedule((prev) => ({
        ...prev,
        checkDateLoading: false,
      }));
    } else {
      setSchedule((prev) => ({
        ...prev,
        checkDateLoading: true,
      }));
      setScheduledDate(null);
      const payload = {
        task_id: props?.selectedRow?.task_id,
        ask_date: schedule?.furtherDateObj,
        instant: true,
        timezone: userTimezone,
        currentSession: currentSession,
        is_further: schedule?.furtherSelectedBtn,
      };
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/notary/available-ron-schedule`,
          {
            payload,
          },
          {
            withCredentials: true,
          }
        );
        if (
          resp.data.isAvailable?.length === 0 &&
          resp.data?.slots?.length > 0
        ) {
          setSchedule((prev) => ({
            ...prev,
            scheduleTimeLeft: 60,
            startTimer: true,
            instantAccepted: false,
            isFurtherAvailable: false,
            unavailableText:
              "The selected date is unavailable. Please choose a different date or select one from the list below.",
            slots: resp.data.slots,
          }));
        } else if (resp.data.success) {
          setSchedule((prev) => ({
            ...prev,
            isFurtherAvailable: true,
            furtherData: resp.data,
            scheduleTimeLeft: 60,
            startTimer: true,
            slots: [],
          }));
        } else {
          setSchedule((prev) => ({
            ...prev,
            furtherError: resp.data.message,
            scheduleTimeLeft: 60,
            firstTimer: false,
            isAvailable: false,
            instantAccepted: false,
            unavailableText:
              "The selected date is unavailable. Please choose a different date.",
            slots: [],
          }));
        }
      } catch (e) {
      } finally {
        setSchedule((prev) => ({
          ...prev,
          checkDateLoading: false,
        }));
      }
    }
  };

  const handleTimeUp = async () => {
    const payload = {
      task_id: props?.selectedRow?.task_id,
      currentSession: currentSession,
    };

    try {
      setSchedule((prev) => ({
        ...prev,
        slots: [],
        slotIndex: -1,
        isInstantAvailable: false,
        isAvailable: false,
        instantData: {},
        unavailableText: "",
        firstTimer: true,
      }));
      setScheduledDate(null);

      if (schedule?.immediateRequested === true) {
        if (
          schedule?.canceledInstant === false &&
          props?.guestUser?.instantRonAccepted === false //! FIX THIS GUEST TO CHANGE IT
        ) {
          setSchedule((prev) => ({
            ...prev,
            instantError:
              "Unfortunately, we have not received any response from the notaries at this moment. We apologize for the inconvenience as they are currently very busy. Please consider scheduling an appointment for a later time. Thank you for your understanding.",
          }));
        }
        setSchedule((prev) => ({
          ...prev,
          immediateRequested: false,
          canceledInstant: false,
        }));
      }

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/notary/clear-ron-schedules`,
        {
          payload,
        },
        {
          withCredentials: true,
        }
      );
    } catch (e) {}
    setSchedule((prev) => ({
      ...prev,
      startTimer: false,
      startInstantTimer: false,
    }));
  };

  useEffect(() => {
    if (schedule?.tempDate && schedule?.tempTime) {
      // Extract values from tempDate and tempTime
      const year = schedule?.tempDate.year();
      const month = schedule?.tempDate.month() + 1; // Adjust for zero-indexed months
      const day = schedule?.tempDate.date();
      const hour = schedule?.tempTime.hour();
      const minute = schedule?.tempTime.minute();

      // Ensure all values are numbers and are valid
      if (
        !isNaN(year) &&
        !isNaN(month) &&
        !isNaN(day) &&
        !isNaN(hour) &&
        !isNaN(minute)
      ) {
        const dateObj = {
          day_of_month: day,
          month: month,
          year: year,
          time: schedule?.tempTime.format("HH:mm"), // Formats the time in 'HH:mm' format
        };

        // Convert tempDate and tempTime to the user's timezone
        const userDateTime = DateTime.fromObject(
          {
            year,
            month,
            day,
            hour,
            minute,
            second: 0, // Set seconds to 0 by default
          },
          { zone: userTimezone }
        );

        // Check if the date and time is in the past
        if (userDateTime < DateTime.now().setZone(userTimezone)) {
          setSchedule((prev) => ({
            ...prev,
            unavailableText: "Selected datetime is in the past.",
            furtherDateObj: null,
          }));
        } else {
          setSchedule((prev) => ({
            ...prev,
            furtherDateObj: dateObj,
            unavailableText: "",
          }));
          setScheduledDate(dateObj);
        }
      } else {
        // If any part of the date or time is invalid, set an appropriate message
        setSchedule((prev) => ({
          ...prev,
          unavailableText: "Invalid date or time selected.",
          furtherDateObj: null,
        }));
      }
    }
  }, [schedule?.tempDate, schedule?.tempTime, userTimezone]);

  useEffect(() => {
    let intervalId;

    if (schedule?.startTimer && schedule?.scheduleTimeLeft > 0) {
      intervalId = setInterval(() => {
        setSchedule((prev) => ({
          ...prev,
          scheduleTimeLeft: prev.scheduleTimeLeft - 1,
        }));
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [schedule?.startTimer, schedule?.scheduleTimeLeft]);

  useEffect(() => {
    let intervalIdInstant;

    if (schedule?.startInstantTimer && schedule?.instantTimeLeft > 0) {
      intervalIdInstant = setInterval(() => {
        setSchedule((prev) => ({
          ...prev,
          instantTimeLeft: prev.instantTimeLeft - 1,
        }));
      }, 1000);
    }

    return () => clearInterval(intervalIdInstant);
  }, [schedule?.startInstantTimer, schedule?.instantTimeLeft]);

  useEffect(() => {
    if (schedule?.scheduleTimeLeft === 0) {
      handleTimeUp();

      setSchedule((prev) => ({
        ...prev,
        startTimer: false,
        furtherSelectedBtn: false,
        isInstantAvailable: false,
        tempDate: null,
        tempTime: null,
        furtherDateObj: null,
        furtherData: null,
        isFurtherAvailable: false,
      }));
    }
  }, [schedule?.scheduleTimeLeft]);

  useEffect(() => {
    if (
      (schedule?.instantTimeLeft === 0 || schedule?.instantTimeLeft < 0) &&
      schedule?.immediateLock === false
    ) {
      handleTimeUp();
      setSchedule((prev) => ({
        ...prev,
        startInstantTimer: false,
        expeditedSelectedBtn: false,
      }));
    }
  }, [schedule?.instantTimeLeft]);

  useEffect(() => {
    if (
      schedule?.submitError !== "" &&
      schedule?.submitError !==
        "** Warning ** You should not leave the page or refresh the page while we are assigning you a notary. The process will take less than a minute."
    ) {
      setSchedule((prev) => ({
        ...prev,
        submitting: false,
      }));
      const interv = setInterval(() => {
        setSchedule((prev) => ({
          ...prev,
          submitError: "",
        }));
      }, 5000);
      return () => clearInterval(interv);
    }
  }, [schedule?.submitError]);

  useEffect(() => {
    if (
      currentUser?.instantRonAccepted === true &&
      schedule?.immediateLock === false
    ) {
      setSchedule((prev) => ({
        ...prev,
        immediateLock: true,
        instantAccepted: true,
      }));
      startInstantRon();
    } else if (
      schedule?.instantAccepted === true &&
      currentUser?.instantRonAccepted === false
    ) {
      setSchedule((prev) => ({
        ...prev,
        instantAccepted: false,
      }));
    }
  }, [currentUser?.instantRonAccepted]);

  return (
    <Modal open={props?.open} sx={{ overflow: "auto" }}>
      <Box
        sx={{
          overflowY: "none",
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "90%",
          maxWidth: "70rem",
          bgcolor: "background.paper",
          borderRadius: "18px",
          boxShadow: 24,
          px: 4,
          py: 2,
          animation: "fadeIn 0.5s ease-in-out",
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h4"
          align="center"
          component="h2"
          color="primary"
        >
          {onlySign
            ? "Your Documents are Now Prepared and Ready for Signing"
            : isScheduled
            ? "Your Remote Online Notarization (RON) Session is Scheduled"
            : "Schedule your Remote Online Notarization (RON) Session"}
        </Typography>
        <Typography
          align="center"
          variant="body2"
          color="primary"
          fontWeight="bold"
          mb={2}
        >
          {onlySign
            ? "Please proceed to sign the documents at your convenience. Once you have signed, the owner will be able to initiate the notarization process."
            : !isScheduled &&
              "Choose a date that is convenient for you. Once the date and time have been selected, we will contact the notaries to see if they are available."}
        </Typography>
        <Box sx={{ position: "absolute", right: 10, top: 10 }}>
          <IconButton
            disabled={currentUser?.instantRonAccepted}
            onClick={() => {
              props?.setOpen(false);
              if (schedule?.startInstantTimer || schedule?.startTimer) {
                handleTimeUp();
              }
              setSchedule(base);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            maxHeight: "35.3rem", // Adjust the max height as needed
            overflowY: "auto",
            height: "calc(90vh - 410px)",
            border: "2px solid #d3d3d3",
            mb: 5,
            p: 2,
            borderRadius: "18px",
            boxShadow: "3px 4px 12px rgba(0, 0, 0, 0.3)", // Added box shadow
          }}
        >
          {onlySign ? (
            <Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img
                  alt="avatar_circle"
                  src="/img/website_img/pp_avatar_circle.png"
                  style={{ width: "18rem", height: "auto", zIndex: 2 }}
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={() => {
                    window.open(
                      props?.selectedRow?.processing_permit?.notarization
                        ?.contractor?.notarization?.bn_session?.signer_url,
                      "_blank"
                    );
                    props?.setOpen(false);
                  }}
                  sx={{ ml: 2.5, zIndex: 1 }}
                  size="large"
                  variant="contained"
                >
                  SIGN YOUR DOCUMENTS
                </Button>
              </Box>
            </Box>
          ) : (
            <ScheduleRONComponent
              setOpen={props?.setOpen}
              schedule={schedule}
              setSchedule={setSchedule}
              handleImmediateRON={handleImmediateRON}
              handleStartClick={handleStartClick}
              scheduledDate={scheduledDate}
              setScheduledDate={setScheduledDate}
              isScheduled={isScheduled}
              ronDate={ronDate}
              sessionUrl={sessionUrl}
              fiveMinBeforeSession={props?.fiveMinBeforeSession}
            />
          )}
        </Box>
        <Box
          sx={{
            borderBottomLeftRadius: "18px",
            borderBottomRightRadius: "18px",
            backgroundColor: "#fff",
            position: "fixed",
            bottom: -20,
            left: 0,
            width: "100%",
            // borderTop: "3px solid #d3d3d3",
            py: 1,
            animation: "slideUp 0.5s ease-in-out",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
            <Typography
              sx={{ mt: 1, mb: 1 }}
              fontWeight="bold"
              align="center"
              variant={props?.tabletSize ? "body2" : "h6"}
              color="error"
            >
              {schedule?.submitError}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ScheduleContractorRonModal;
