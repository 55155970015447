import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TermsAndConditionsModal from "../modals/TermsAndConditionsModal";
import InvoiceGrid from "../InvoiceGrid";
import LottieAnimation from "../../animation/LottieAnimation";
import {
  Box,
  Grid,
  Card,
  Button,
  Typography,
  useMediaQuery,
  CardHeader,
  CardContent,
  Drawer,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { PencilIcon } from "@heroicons/react/24/outline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditCard from "../EditCard";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { LoadingButton } from "@mui/lab";
import NotaryInvoiceGrid from "../NotaryInvoiceGrid";
import CollapsiblePaymentCard from "../notary/modals/CollapsiblePaymentCard";
import MobileFooter from "../layout/MobileFooter";
import { useTour } from "@reactour/tour";
import { tourSteps } from "../../data/walkthroughDemo/steps";

const Payment = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [cards, setCards] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [paymentChange, setPaymentChange] = useState(false);
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);
  const [errorText, setErrorText] = useState("");
  const [open, setOpen] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
  const [notaryInvoiceList, setNotaryInvoiceList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [defLoading, setDefLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [disableAll, setDisableAll] = useState(false);
  const [add, setAdd] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [terms, setTerms] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const { isOpen, currentStep, setCurrentStep } = useTour();

  //? SCREEN SIZES ##################################################################
  const below1450 = useMediaQuery("(max-width:1450px)");
  const iphone12Height = useMediaQuery("(min-height: 840px)");
  const smallHeight = useMediaQuery("(max-height: 735px)");
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const mobileSizeUp = useMediaQuery(theme.breakpoints.up("md"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const largePC = useMediaQuery(theme.breakpoints.up("xl"));
  //? ###############################################################################
  const scrollableBoxRef = useRef(null);

  useEffect(() => {
    // Check if the current step is related to the subscription page
    if (isOpen && currentStep === 25 && !loading) {
      // When loading is complete, move to the next tour step
      setCurrentStep((s) => (s === tourSteps?.length - 1 ? 0 : s + 1));
    }
  }, [isOpen, currentStep, loading]);

  const scrollToBottom = () => {
    // Scroll logic
    const scrollableElement = scrollableBoxRef.current;
    if (scrollableElement) {
      scrollableElement.scrollTop = scrollableElement.scrollHeight;
    }
  };

  let count = 0;

  const stripe = useStripe();
  const elements = useElements();

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const createCard = async (name) => {
    try {
      const paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement("card"),
        billing_details: {
          name: name,
          email: currentUser?.subuser
            ? currentUser?.subuser?.email
            : currentUser?.user?.email,
        },
      });
      return paymentMethod;
    } catch (err) {
      setErrorText(err.message);
    }
  };

  console.log(cards[currentIndex]);

  const handleRemovePayment = async () => {
    if (isOpen) {
      return;
    }
    setRemoveLoading(true);
    setDisableAll(true);
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/remove-payment-card`,
        { card: cards[currentIndex], currentSession: currentSession },
        { withCredentials: true }
      );

      if (resp.data.success) {
        setErrorText(resp.data.message);
      }

      setCurrentIndex(0);
      setRemoveLoading(false);
      setDisableAll(false);
    } catch (e) {
      setRemoveLoading(false);
      setDisableAll(false);
    }
  };

  const handleSetDefault = async () => {
    if (isOpen) {
      return;
    }
    setDefLoading(true);
    setDisableAll(true);
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/set-default-card`,
        { card: cards[currentIndex], currentSession: currentSession },
        { withCredentials: true }
      );

      if (resp.data.success) {
        setErrorText(resp.data.message);
      }
      setCurrentIndex(0);
      setDefLoading(false);
      setDisableAll(false);
    } catch (e) {
      setDefLoading(false);
      setDisableAll(false);
    }
  };
  useEffect(() => {
    if (errorText !== "") {
      const timer = setTimeout(() => setErrorText(""), 5000);
      return () => clearTimeout(timer);
    }
  }, [errorText]);

  useEffect(() => {
    if (currentUser.user.expired === true) {
      navigate("/subscription");
      return;
    }

    if (currentUser?.subuser && currentUser?.subuser.role === "Basic User") {
      navigate("/dashboard");
    }
  }, [currentUser]);

  useEffect(() => {
    const get_card = async () => {
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/get-payment-details`,
          { currentSession: currentSession },

          { withCredentials: true }
        );
        if (resp.data.success) {
          if (!currentUser?.subuser || currentUser.subuser !== "Basic") {
            if (
              currentUser?.payment_cards &&
              currentUser?.payment_cards?.length > 0
            ) {
              setCards(
                currentUser?.payment_cards?.map((card) => {
                  const lastTwo = String(card.exp_year).slice(-2);
                  const first = card.exp_month;

                  return {
                    id: card.id,
                    cardNum: "**** **** **** " + card.last4,
                    cardName: card.name ? card.name : card.card_holder,
                    expires:
                      (first < 10 ? "0" + String(first) : String(first)) +
                      "/" +
                      lastTwo,
                    default: card.default,
                  };
                })
              );
            } else {
              setCards([]);
            }
          }
          setNotaryInvoiceList(resp.data.data.notary_invoices);
          setInvoiceList(resp.data.data.invoices);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    };
    if (count < 1) {
      get_card();
      count += 1;
    }
  }, []);

  useEffect(() => {
    if (currentUser?.payment_cards && currentUser?.payment_cards?.length > 0) {
      setCards(
        currentUser?.payment_cards?.map((card) => {
          const lastTwo = String(card.exp_year).slice(-2);
          const first = card.exp_month;

          return {
            id: card.id,
            cardNum: "**** **** **** " + card.last4,
            cardName: card.name ? card.name : card.card_holder,
            expires:
              (first < 10 ? "0" + String(first) : String(first)) +
              "/" +
              lastTwo,
            default: card.default,
          };
        })
      );
    } else {
      setCards([]);
    }
  }, [currentUser?.payment_cards]);

  return (
    <>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {tabletSize ? (
              <>
                <Box sx={{ mb: 2 }}>
                  <img
                    style={{
                      width: "10rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    alt="Permit Rockstar Logo"
                    src="/img/website_img/PermitRockstar.png"
                  />
                </Box>
                <LottieAnimation />
              </>
            ) : (
              <CircularProgress color="warning" size="10rem" />
            )}
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              mx: largePC ? "auto" : 0,
              maxWidth: "1500px",
              py: 1,
              backgroundColor: "#EEF2F6",
              mb: tabletSize ? "5rem" : undefined,
            }}
          >
            <Grid
              container
              sx={{
                px: mobileSize ? 1 : tabletSize ? 2 : 3,
              }}
            >
              <Grid container justifyContent="center">
                <>
                  <Grid item xs={12} mb={3} mt={3}>
                    <Card className="step27" sx={{ position: "relative" }}>
                      <CardHeader
                        title={"Manage Card"}
                        sx={{
                          pt: tabletSize ? 2 : undefined,
                          textAlign: isOpen ? "center" : "left",
                        }}
                      />

                      <CardContent sx={{ p: tabletSize ? 1 : undefined }}>
                        {cards.length === 0 ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="h5" color="primary">
                              You haven't added any default cards yet.
                            </Typography>
                            <Typography
                              variant="body1"
                              color="primary"
                              fontWeight="bold"
                            >
                              Please add your payment card details to enable
                              quicker checkout and access to certain services.
                            </Typography>
                            <Button
                              disabled={disableAll}
                              variant="contained"
                              color="success"
                              startIcon={<AddIcon color="#fff" width={20} />}
                              onClick={() => {
                                if (isOpen) {
                                  return;
                                }
                                setOpenDrawer(true);
                                setAdd(true);
                              }}
                              sx={{ mt: 5 }}
                            >
                              Add Credit Card
                            </Button>
                          </Box>
                        ) : (
                          <Grid container spacing={1}>
                            <Grid item md={6} xs={12}>
                              <CollapsiblePaymentCard
                                paymentCards={cards}
                                currentIndex={currentIndex}
                                handleDotClick={handleDotClick}
                                scrollToBottom={scrollToBottom}
                                screenLimit={below1450}
                                documentUploader={true}
                                tabletSize={tabletSize}
                                mobileSize={mobileSize}
                                fromPayment={true}
                              />
                            </Grid>
                            <Grid
                              item
                              md={6}
                              xs={12}
                              sx={{
                                order: mobileSizeUp ? 1 : 2,
                                mt: tabletSize ? 2 : undefined,
                              }}
                            >
                              <Grid container spacing={1}>
                                <Grid item sm={6} xs={12}>
                                  <Button
                                    disabled={disableAll}
                                    fullWidth
                                    variant="contained"
                                    color="success"
                                    startIcon={
                                      <AddIcon color="#fff" width={20} />
                                    }
                                    onClick={() => {
                                      if (isOpen) {
                                        return;
                                      }
                                      setOpenDrawer(true);
                                      setAdd(true);
                                    }}
                                  >
                                    Add Credit Card
                                  </Button>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <Button
                                    disabled={
                                      disableAll ||
                                      (currentUser?.subuser &&
                                        cards[currentIndex]?.default === true)
                                    }
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    startIcon={
                                      <PencilIcon color="#fff" width={20} />
                                    }
                                    onClick={() => {
                                      if (isOpen) {
                                        return;
                                      }
                                      setOpenDrawer(true);
                                      setAdd(false);
                                    }}
                                  >
                                    Edit Credit Card
                                  </Button>
                                </Grid>

                                {!currentUser?.subuser && (
                                  <Grid item sm={6} xs={12}>
                                    <LoadingButton
                                      loading={defLoading}
                                      onClick={handleSetDefault}
                                      disabled={
                                        cards[currentIndex]?.default ||
                                        disableAll
                                      }
                                      fullWidth
                                      variant="contained"
                                      color="warning"
                                      startIcon={
                                        <CheckCircleOutlineIcon
                                          color="#fff"
                                          width={20}
                                        />
                                      }
                                    >
                                      {cards[currentIndex]?.default
                                        ? "Default Payment Card"
                                        : "Set as Default Payment Card"}
                                    </LoadingButton>
                                  </Grid>
                                )}

                                <Grid item sm={6} xs={12}>
                                  <LoadingButton
                                    loading={removeLoading}
                                    onClick={handleRemovePayment}
                                    disabled={
                                      cards[currentIndex]?.default || disableAll
                                    }
                                    fullWidth
                                    variant="contained"
                                    color="error"
                                    startIcon={
                                      <DeleteIcon color="#fff" width={20} />
                                    }
                                  >
                                    Remove Credit Card
                                  </LoadingButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}

                        <Typography
                          variant="body1"
                          color="#FF6666"
                          fontWeight="bold"
                          textAlign="center"
                          sx={{ mt: "1rem" }}
                        >
                          {errorText}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Drawer
                    PaperProps={{
                      sx: {
                        width: largePC ? "55vw" : "70vw",
                      },
                    }}
                    anchor="right"
                    open={openDrawer}
                    onClose={() => {}}
                  >
                    <EditCard
                      currentUser={currentUser}
                      add={add}
                      setCurrentIndex={setCurrentIndex}
                      cards={cards}
                      setCards={setCards}
                      currentIndex={currentIndex}
                      setOpen={setOpen}
                      createCard={createCard}
                      CardElement={CardElement}
                      errorText={errorText}
                      setErrorText={setErrorText}
                      setOpenDrawer={setOpenDrawer}
                      setPaymentChange={setPaymentChange}
                      terms={terms}
                      setTerms={setTerms}
                      termsError={termsError}
                      setTermsError={setTermsError}
                    />
                  </Drawer>
                </>

                {currentUser && (
                  <>
                    <Grid item xs={12} md={12} lg={12}>
                      <InvoiceGrid
                        payments={true}
                        invoiceRow={invoiceList}
                        title="Subscription Invoices"
                        tabletSize={tabletSize}
                        mobileSize={mobileSize}
                        iphone12Height={iphone12Height}
                        smallHeight={smallHeight}
                      />
                    </Grid>

                    <Grid sx={{ mt: 3, mb: 3 }} item xs={12} md={12} lg={12}>
                      <NotaryInvoiceGrid
                        payments={true}
                        notaryInvoiceRow={notaryInvoiceList}
                        title="Notarization and Recording Invoices"
                        tabletSize={tabletSize}
                        mobileSize={mobileSize}
                        iphone12Height={iphone12Height}
                        smallHeight={smallHeight}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {tabletSize && <MobileFooter pos="bottom" pl="0.5rem" />}
      <TermsAndConditionsModal open={open} onClose={handleCloseModal} />
    </>
  );
};

export default Payment;
