// PromoDialogContent.js
import React, { useState } from "react";
import {
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";

const PpPaymentPop = ({
  handleCancel,
  handleConfirm,
  setCurrentPopup,
  selectedRow,
  downloading,
  currentUser,
  setPaymentModalOpen,
  setOpen,
  handleTransition,
  setPermitDrawerOpen,
}) => {
  return (
    <>
      <DialogTitle
        color="primary"
        variant="h4"
        id="responsive-dialog-title"
        sx={{ textAlign: "center" }}
      >
        {currentUser?.user?.analytics?.free_active
          ? "Free Building Permit Package Limit Reached"
          : "Purchase Building Permit Package to Continue"}
      </DialogTitle>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <img
          alt="avatar_circle"
          src="/img/website_img/pp_avatar_circle.png"
          style={{ width: "15rem", height: "auto", zIndex: 2 }}
        />
      </Box>
      <DialogContent sx={{ px: 2, pt: 0 }}>
        <Box>
          <DialogContentText sx={{ mb: 1, mt: 0, textAlign: "center" }}>
            To proceed with creating a new Notice of Commencement (NOC) form, a
            one-time purchase is required. This quick, secure payment will give
            you immediate access to generate your NOC form and keep your project
            moving forward seamlessly.
          </DialogContentText>

          <DialogContentText
            color="primary"
            sx={{ mb: 0, textAlign: "center", fontWeight: "bold" }}
          >
            Did you know?
          </DialogContentText>
          <DialogContentText sx={{ mb: 0, mt: 0, textAlign: "center" }}>
            With our higher-tier subscription plans, you’ll gain unlimited
            access to NOC forms along with additional premium features designed
            to streamline your workflow. Upgrading could save you both time and
            cost in the long run, offering an all-inclusive experience.
          </DialogContentText>

          <DialogContentText
            color="primary"
            sx={{ mb: 3, textAlign: "center", fontWeight: "bold", px: 1 }}
          >
            <span style={{ fontSize: "26px", color: "#10b981" }}>Only</span>{" "}
            {/* <span
              style={{
                textDecoration: "line-through",
                color: "red",
                fontSize: "20px",
              }}
            >
              $99
            </span>{" "} */}
            <span style={{ fontSize: "30px", color: "#10b981" }}>$</span>
            <span style={{ fontSize: "36px", color: "#10b981" }}>
              {currentUser?.service_tiers?.noc_price}
            </span>
          </DialogContentText>
        </Box>

        <Box
          sx={{
            gap: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Button
            color="success"
            onClick={() => setPaymentModalOpen(true)}
            fullWidth
            variant="contained"
          >
            PROCEED
          </Button>
          <Button
            onClick={() => {
              setPaymentModalOpen(false);
              setOpen(false);
              setPermitDrawerOpen(false);
              setPaymentModalOpen(false);
            }}
            color="error"
            fullWidth
          >
            Cancel
          </Button>
        </Box>
      </DialogContent>
    </>
  );
};

export default PpPaymentPop;
