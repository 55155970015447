import {
  Grid,
  Stack,
  Typography,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
  Button,
  Toolbar,
  Snackbar,
  Alert,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { loadStripe } from "@stripe/stripe-js";
import { cityScraperList } from "../../data/cityScraperList";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ReactCardFlip from "react-card-flip";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import Magnifier from "../customComponents/Magnifier";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { useState, useEffect, useRef, Suspense, useCallback } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Footer from "../layout/Footer";
import DownloadIcon from "@mui/icons-material/Download";

import MoreResultsList from "./MoreResultsList";
import PermitPackagePaymentCard from "../buildingComponents.js/PermitPackagePaymentCard";
import { loadComponent } from "../../utils/loadPermitComponent";
import { loadInitials } from "../../utils/loadPermitInitials";
import CityScraperStartModal from "../modals/CityScraperStartModal";
import { handleLoadPaisTemplates } from "../../functions/paisHelperFunctions";
import PromoModal from "../popover/promoPopover/PromoModal";
import PermitPackagePaymentModal from "../modals/PermitPackagePaymentModal";
import axios from "axios";
import DemoPopPaymentModal from "../popover/promoPopover/DemoPopPaymentModal";
import { updatePermitPackage } from "../../store/actions/dataActions";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import AddPaymentCardModal from "../modals/AddPaymentCardModal";

const OpenDocuments = ({
  designProList,
  setSelectedRow,
  notaryLoading,
  selectedRow,
  up1177,
  creatingDoc,
  setCreatingDoc,
  creatingDocTitle,
  setCreatingDocTitle,
  usingTemplate,
  setUsingTemplate,
  savedProjects,
  setPermitDrawerOpen,
  documentImage,
  setDocumentImage,
  leftValue,
  setLeftValue,
  permitDrawerOpen,
  setTempLoading,
  serviceTiers,
  plan,
  paymentCards,
  rightValue,
  setRightValue,
  currentPopup,
  setCurrentPopup,
  promoModalOpen,
  setPromoModalOpen,
  setCreateLoading,
}) => {
  const dispatch = useDispatch();
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);

  const theme = useTheme();
  const leftGridRef = useRef(null);
  const RightGridRef = useRef(null);
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const stripe = useStripe();
  const elements = useElements();

  const [createDocLoading, setCreateDocLoading] = useState(false);
  const [consentAlert, setConsentAlert] = useState(false);
  const [templateLoading, setTemplateLoading] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [addPaymentModalOpen, setAddPaymentModalOpen] = useState(false);

  //! NOC STATES /////////////////////////////////////
  const [nocStates, setNocStates] = useState({
    isNocPreview: false,
    previewLoading: false,
    createNocLoading: false,
    isCreatingNoc: false,
    dynamicValues: {},
    isSuretyRadio: false,
    emptyPermitConfirmed: false,
    pdfProgressModalOpen: false,
    inQueueMessage: "",
    inQueueDrawer: false,
    emptyPermitOpen: false,
    emptyPermitConfirmed: false,
    items: [],
    dotStates: {},
    completed: {},
    intervalIds: {},
    isFinished: false,
    initializeQueue: true,
    isMoreError: false,
    isFailedError: false,
    waitingList: false,
    cardFlip: false,
    moreResultRow: null,
    editNocLoading: false,
    isEditNoc: false,
  });
  //! /////////////////////////////////////////////
  //! ROOFING_PERMIT /////////////////////////////////////
  const [roofingStates, setRoofingStates] = useState({
    isCreatingRoofing: false,
  });
  //! /////////////////////////////////////////////
  //! BUILDING_PERMIT /////////////////////////////////////
  const [buildingStates, setBuildingStates] = useState({
    designProListState: [],
    projectContactListState: [],
    feeSimpleListState: [],
    bondingCompanyListState: [],
    mortgageLenderListState: [],
    addingNewDesignPro: false,
    submitDisabled: false,
    projectSubmitDisabled: false,
    feeSimpleSubmitDisabled: false,
    bondingSubmitDisabled: false,
    mortgageSubmitDisabled: false,
    addingNewProject: false,
    addingNewFeeSimple: false,
    addingNewBonding: false,
    addingNewMortgage: false,
    workDesc: "",
    createBuildingLoading: false,
    isCreatingBuilding: false,
  });
  //! /////////////////////////////////////////////
  //! PAIS STATES ////////////////////////////////
  const [paisStates, setPaisStates] = useState({
    loadingData: false,
    paisSearching: false,
    productsData: null,
    manufacturerOptions: [],
    flNumberOptions: [],
    manDetailsModal: false,
    descriptionOptions: [],
    modelOptions: [],
    addingProduct: false,
    editingProduct: false,
    expandedCategory: false,
    editExpanded: false,
    detailsExpanded: false,
    addProductExpanded: false,
    templateExpanded: true,
    editData: false,
    addNewWaiting: true,
    useTemplate: false,
    tempContinue: true,
    saveIsReady: false,
    saveTemplateLoading: false,
    formAccDisabled: false,
    snackbarOpen: false,
    paisSelectedTemplate: {
      id: "",
      name: "",
    },
    savePaisSelectedTemplate: {
      id: "",
      name: "",
    },
    savePaisName: "",
    templateError: "",
    templateLoading: false,
    openModal: false,
    descriptionValue: "",
    noProductError: "",
    resettingData: false,
    startDeleteProduct: false,
    category: "",
    subCategory: "",
    isCreatingPais: false,
  });
  //! /////////////////////////////////////////////
  //! OBTAIN_PERMIT STATES ////////////////////////////////
  const [obtainPermitStates, setObtainPermitStates] = useState({
    permissionLoading: false,
    isCreatingObtainPermit: false,
  });
  //! /////////////////////////////////////////////
  //! ENERGY STATES ////////////////////////////////
  const [energyStates, setEnergyStates] = useState({
    isCreatingEnergy: false,
  });
  //! /////////////////////////////////////////////
  //! ASBESTOS STATES ////////////////////////////////
  const [asbestosStates, setAsbestosStates] = useState({
    isCreatingAsbestos: false,
  });
  //! /////////////////////////////////////////////
  //! OWNERSHIP STATES ////////////////////////////////
  const [ownershipStates, setOwnershipStates] = useState({
    isCreatingOwnership: false,
  });
  //! /////////////////////////////////////////////
  //! PERMITFORM STATES ////////////////////////////////
  const [permitFormStates, setPermitFormStates] = useState({
    isCreatingForm: false,
  });

  //! /////////////////////////////////////////////
  const [snackOpen, setSnackOpen] = useState(false);
  const [descriptionError, setDescriptionError] = useState("");
  const [showSelectedCity, setShowSelectedCity] = useState("");
  const [LoadedComponent, setLoadedComponent] = useState(null);
  const [initials, setInitials] = useState(null);
  const [formError, setFormError] = useState(false);
  const [cityModalOpen, setCityModalOpen] = useState(false);
  const [paymentErrorText, setPaymentErrorText] = useState("");

  const handlePayment = async () => {
    try {
      setPaymentLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/process-addition-payment`,
        {
          paymentCard: paymentCards[activeStep]?.id,
          task_id: selectedRow?.task_id,
          currentSession: currentSession,
          addition: currentPopup,
        },
        { withCredentials: true }
      );
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE); // Replace with your actual publishable key
      const result = await stripe.confirmCardPayment(resp.data.data);
      if (result.error) {
        setPaymentErrorText(result.error.message);
        // Handle the error on the frontend
      } else {
        // The payment has been processed!
        if (
          result.paymentIntent.status === "succeeded" &&
          resp.data.valid === true
        ) {
          const paymentIntent = result.paymentIntent;
          const resp = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/finish-addition-payment`,
            {
              paymentIntent,
              task_id: selectedRow?.task_id,
              currentSession: currentSession,
              addition: currentPopup,
            },
            { withCredentials: true }
          );
          if (resp?.data?.success) {
            await dispatch(updatePermitPackage(resp?.data?.data));
            setSnackOpen(true);
            setPaymentModalOpen(false);
            setPromoModalOpen(false);
          }
        }
      }
    } catch (e) {
    } finally {
      setPaymentLoading(false);
    }
  };

  // useEffect(() => {
  //   setCreateLoading(false);
  // }, []);

  const resetCreateLoading = useCallback(() => {
    setCreateLoading(false);
  }, [setCreateLoading]);

  useEffect(() => {
    resetCreateLoading();
  }, [resetCreateLoading]);

  useEffect(() => {
    const checkConditionsAndUpdatePopup = () => {
      if (currentUser?.tier?.monthly_restart) {
        const total_noc = currentUser?.user?.analytics?.availableNocInPeriod;
        const total_pp = currentUser?.user?.analytics?.availablePPInPeriod;
        const created_pp = currentUser?.user?.analytics?.ppCreatedInPeriod;
        const created_noc = currentUser?.user?.analytics?.nocCreatedInPeriod;
        const free_active = currentUser?.user?.analytics?.free_active;
        const free_time_left = currentUser?.user?.analytics?.free_days_left;

        const noc_paid = selectedRow?.payments?.create_noc?.paid;
        const vip_paid = selectedRow?.payments?.vip?.paid;
        const pp_paid = selectedRow?.payments?.create_permit_package?.paid;

        const noc_created = selectedRow?.noc_created;
        const pp_created = selectedRow?.permit_package_created;

        const remaining_noc = total_noc - created_noc;
        const remaining_pp = total_pp - created_pp;

        const noc_need_pay =
          !noc_created &&
          !noc_paid &&
          !vip_paid &&
          (remaining_noc <= 0 || !free_active || free_time_left <= 0);

        const pp_need_pay =
          !pp_created &&
          !pp_paid &&
          !vip_paid &&
          (remaining_pp <= 0 || !free_active || free_time_left <= 0);

        // const is_vip_pop_open = noc_need_pay && pp_need_pay && leftValue === 0 && !noc_need_pay && !pp_need_pay;
        const is_vip_pop_open = noc_need_pay && leftValue === 0;
        const is_pp_pop_open = pp_need_pay && leftValue !== 0;

        if (!nocStates?.pdfProgressModalOpen) {
          if (is_vip_pop_open) {
            setCurrentPopup("vip");
            setTimeout(() => {
              setPromoModalOpen(true);
            }, 600);
          } else if (is_pp_pop_open) {
            setCurrentPopup("ppPaymentPop");
            setTimeout(() => {
              setPromoModalOpen(true);
            }, 600);
          } else {
            setPromoModalOpen(false);
            setCurrentPopup("");
          }
        }
      }
    };

    checkConditionsAndUpdatePopup();
  }, [selectedRow, leftValue, creatingDoc, promoModalOpen]);

  // useEffect(() => {
  //   console.log("RAN");
  //   if (
  //     currentUser?.tier?.monthly_restart &&
  //     // !promoModalOpen &&
  //     creatingDoc !== "noc" &&
  //     leftValue !== 0
  //   ) {
  //     console.log("EXECUTED");

  //     if (
  //       !selectedRow?.payments?.vip?.paid &&
  //       !selectedRow?.payments?.create_permit_package?.paid
  //     ) {
  //       const total_pp = currentUser?.user?.analytics?.availablePPInPeriod;
  //       const created_pp = currentUser?.user?.analytics?.ppCreatedInPeriod;

  //       if (total_pp >= created_pp) {
  //         setCurrentPopup("ppPaymentPop");
  //         setPromoModalOpen(true);
  //       }
  //     }
  //   }
  // }, [selectedRow, leftValue, creatingDoc, promoModalOpen]);

  // useEffect(() => {
  //   console.log("RAN22");
  //   setTimeout(() => {
  //     if (
  //       !promoModalOpen &&
  //       !selectedRow?.noc_created &&
  //       !selectedRow?.payments?.create_noc?.paid &&
  //       !selectedRow?.payments?.vip?.paid &&
  //       currentUser?.tier?.monthly_restart
  //     ) {
  //       setCurrentPopup("vip");
  //       setPromoModalOpen(true);
  //     }
  //   }, 600);
  // }, [creatingDoc]);

  useEffect(() => {
    const complex = selectedRow?.documents?.[creatingDoc]?.sections
      ? true
      : false;

    const load = async () => {
      const { default: Component } = await loadComponent(
        creatingDoc,
        selectedRow?.county,
        selectedRow?.city,
        complex
      );
      const Initials = await loadInitials(
        creatingDoc,
        selectedRow?.county,
        selectedRow?.city
      );

      setLoadedComponent(() => Component);
      setInitials(Initials);
    };

    if (creatingDoc && selectedRow?.county) {
      // Only require county, as city may be optional
      load();
    }
  }, [creatingDoc, selectedRow?.county, selectedRow?.city]);

  useEffect(() => {
    if (descriptionError) {
      const interv = setTimeout(() => {
        setDescriptionError(false);
      }, 6000);
      return () => clearTimeout(interv);
    }
  }, [descriptionError]);

  useEffect(() => {
    if (leftGridRef.current) {
      leftGridRef.current.scrollTo(0, 0);
    }
  }, [documentImage]);

  useEffect(() => {
    if (RightGridRef.current) {
      RightGridRef.current.scrollTo(0, 0);
    }
  }, [leftValue]);

  const formatCity = (city) => {
    // Split the string by underscores
    const words = city.split("_");

    // Capitalize the first letter of each word
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    // Join the words and add 'Creating' and 'Form'
    return `${capitalizedWords.join(" ")}`;
  };

  // //? PROMO MODALS DOWNLOADS /////
  // const handlePromoDownload = () => {
  //   setPromoModalOpen(true);
  // };

  //? ################################
  const handleIMG = () => {
    return (
      <>
        {documentImage?.map((item, index) => {
          const src = item.startsWith("https")
            ? item
            : `${process.env.REACT_APP_URL}${item}`;
          return (
            <Magnifier key={index} src={src} lensSize={400} zoomFactor={4} />
          );
        })}
      </>
    );
  };

  const handleIconButton = () => {
    if (buildingStates?.submitDisabled || buildingStates?.addingNewDesignPro) {
      return;
    }

    if (["pais", "pass"].includes(creatingDoc)) {
      setPaisStates((prev) => ({
        ...prev,
        resettingData: true,
      }));
      setUsingTemplate(false);
    }

    if (creatingDoc === "noc") {
      setNocStates((prevState) => ({
        ...prevState,
        cardFlip: false,
      }));
    }

    if (selectedRow?.city_scraper?.is_accepted) {
      setRightValue(4);
    } else if (
      selectedRow?.permit_package_created ||
      (selectedRow?.noc_created &&
        selectedRow?.selected_documents?.length === 1)
    ) {
      if (rightValue !== 2) {
        setRightValue(2);
      }
    }
    setPermitDrawerOpen(false);
  };

  const handleNavigate = useCallback(
    async (direction) => {
      // try {
      let newIndex;
      let nextForm;
      if (direction === "back") {
        newIndex = leftValue - 1;
        // setBackLoading(true);
      } else {
        // setForwardLoading(true);
        newIndex = leftValue + 1;
      }

      if (direction === "back") {
        nextForm = selectedRow?.selected_documents[leftValue - 1];
      } else {
        nextForm = selectedRow?.selected_documents[leftValue + 1];
      }

      setCreatingDoc(nextForm?.name);
      setCreatingDocTitle(nextForm?.label);
      setLeftValue(newIndex);
      setNocStates((prevState) => ({
        ...prevState,
        cardFlip: false,
      }));
    },
    [
      leftValue,
      selectedRow,
      setCreatingDoc,
      setCreatingDocTitle,
      setLeftValue,
      setNocStates,
    ]
  );

  const snackHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const consentHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setConsentAlert(false);
  };

  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          overflow: "hidden",
          width: "100%",
        }}
      >
        {/* LEFT SIDE */}
        <Grid
          ref={leftGridRef}
          item
          md={!up1177 ? 6 : 6}
          sx={{ maxHeight: "90vh", overflow: "auto", position: "relative" }}
        >
          {creatingDoc === "noc" && selectedRow?.documents?.noc?.more ? (
            <Box>
              <IconButton
                sx={{
                  display: "block",
                  position: "absolute",
                  left: 20,
                  top: 20,
                  zIndex: 2,
                  backgroundColor: "#fff",
                }}
                onClick={() =>
                  setNocStates((prevState) => ({
                    ...prevState,
                    cardFlip: !prevState.cardFlip,
                  }))
                }
              >
                {nocStates.cardFlip === true ? (
                  <CloseIcon />
                ) : (
                  <FormatListBulletedIcon />
                )}
              </IconButton>
              <ReactCardFlip
                isFlipped={nocStates.cardFlip}
                flipDirection="horizontal"
                flipSpeedBackToFront={1}
                flipSpeedFrontToBack={1}
              >
                <Box>{handleIMG()}</Box>
                <Box sx={{ height: "100vh" }}>
                  <MoreResultsList
                    addressRow={selectedRow?.documents?.noc?.more}
                    handleRowClick={(row) => {
                      setNocStates({ ...nocStates, moreResultRow: row });
                    }}
                  />
                </Box>
              </ReactCardFlip>
            </Box>
          ) : (
            <Box>{handleIMG()}</Box>
          )}
        </Grid>

        {/* RIGHT SIDE */}
        <Grid
          item
          md={!up1177 ? 6 : 6}
          sx={{
            position: "relative",
            mb: 1,
            mt: 2,
            height: "100vh",

            borderLeft: "4px solid #f3f3f3",
          }}
        >
          {/* HEADER */}
          <IconButton
            disabled={
              createDocLoading ||
              paisStates?.loadingData ||
              paisStates?.saveTemplateLoading ||
              nocStates.editNocLoading
            }
            onClick={handleIconButton}
            sx={{ position: "absolute", right: 10 }}
          >
            <CloseIcon disabled={notaryLoading} />
          </IconButton>
          <Snackbar
            open={consentAlert}
            autoHideDuration={26000}
            onClose={consentHandleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={consentHandleClose}
              severity="success"
              sx={{
                width: "100%",
                backgroundColor: "#388e3c", // Green shade for success
                color: "#fff",
                fontWeight: "bold",
              }}
              iconMapping={{
                success: <CheckCircleIcon sx={{ color: "#fff" }} />, // Changed to success icon
              }}
            >
              Consent Accepted! The city permitting process will automatically
              start when a building permit is created.
            </Alert>
          </Snackbar>
          <Box
            sx={{
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <>
              <Typography color="primary" mx={1} align="center" variant="h5">
                Creating {creatingDocTitle}
              </Typography>
              <Typography color="primary" variant="h6">
                {leftValue + 1} / {selectedRow?.selected_documents?.length}
              </Typography>
            </>

            <Typography
              variant="h6"
              sx={{
                mb: 0.5,
                mt: 0,
              }}
              textAlign="center"
            >
              {selectedRow?.city && selectedRow?.city !== "county_specific"
                ? `${formatCity(selectedRow?.city)} - ${
                    selectedRow?.county
                  } County`
                : `${selectedRow?.county} County`}
            </Typography>
            {creatingDoc === "pais" && (
              <Typography align="center" fontWeight="bold" variant="body2">
                Product Approval form is saved when you load a template, add a
                product or edit/remove a product
              </Typography>
            )}
          </Box>
          {/* TEXTFIELD/CARD */}
          <Box
            ref={RightGridRef}
            sx={{
              height:
                creatingDoc === "pais" && paisStates?.paisSearching
                  ? "calc(100% - 365px)"
                  : "calc(100% - 300px)",
              overflow: "auto",
              px: 0.3,
              mt: 0,
              pb: 1.5,
              pt: 0.3,
              borderTop: "4px solid #f3f3f3",
              borderBottom: "4px solid #f3f3f3",
            }}
          >
            <Snackbar
              open={snackOpen}
              autoHideDuration={6000}
              onClose={snackHandleClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={snackHandleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                Payment Successful. Thank you
              </Alert>
            </Snackbar>
            {
              // serviceTiers?.permit_package !== "Unlimited" &&
              // !selectedRow?.payments?.paid &&
              // !selectedRow?.payments?.invoice_num &&
              // creatingDoc !== "noc" ? (
              //   <PermitPackagePaymentCard
              //     plan={plan}
              //     serviceTiers={serviceTiers}
              //     county={selectedRow?.county}
              //     paymentCards={paymentCards}
              //     currentSession={currentSession}
              //     task_id={selectedRow?.task_id}
              //     setSelectedRow={setSelectedRow}
              //     selectedCity={showSelectedCity}
              //     setSelectedCity={setShowSelectedCity}
              //     setSnackOpen={setSnackOpen}
              //   />
              // ) :
              //! RENDER THE DYNAMIC COMPONENT //////////////////////////////////
              LoadedComponent ? (
                <Suspense fallback={<div>Loading...</div>}>
                  <LoadedComponent
                    //! STATES
                    nocStates={nocStates}
                    setNocStates={setNocStates}
                    roofingStates={roofingStates}
                    setRoofingStates={setRoofingStates}
                    buildingStates={buildingStates}
                    setBuildingStates={setBuildingStates}
                    paisStates={paisStates}
                    setPaisStates={setPaisStates}
                    obtainPermitStates={obtainPermitStates}
                    setObtainPermitStates={setObtainPermitStates}
                    energyStates={energyStates}
                    setEnergyStates={setEnergyStates}
                    asbestosStates={asbestosStates}
                    setAsbestosStates={setAsbestosStates}
                    ownershipStates={ownershipStates}
                    setOwnershipStates={setOwnershipStates}
                    permitFormStates={permitFormStates}
                    setPermitFormStates={setPermitFormStates}
                    //! //////////////////////////////////////////
                    leftValue={leftValue}
                    currentSession={currentSession}
                    setDocumentImage={setDocumentImage}
                    permitDrawerOpen={permitDrawerOpen}
                    setPermitDrawerOpen={setPermitDrawerOpen}
                    setSelectedRow={setSelectedRow}
                    selectedRow={selectedRow}
                    setTempLoading={setTempLoading}
                    designProList={designProList}
                    savedProjects={savedProjects}
                    createDocLoading={createDocLoading}
                    setCreateDocLoading={setCreateDocLoading}
                    creatingDoc={creatingDoc}
                    initials={initials}
                    usingTemplate={usingTemplate}
                    setUsingTemplate={setUsingTemplate}
                    setDescriptionError={setDescriptionError}
                    setCityModalOpen={setCityModalOpen}
                    currentUser={currentUser}
                    currentPopup={currentPopup}
                    setCurrentPopup={setCurrentPopup}
                    setPromoModalOpen={setPromoModalOpen}
                  />
                </Suspense>
              ) : null
            }
            {/* ************************************** */}
          </Box>
          {/* {creatingDoc ? ( */}
          <Toolbar
            sx={{
              px: 0,
              py: 0.5,
              position: "fixed",
              bottom: 88.5,
              left: 0,
              right: 0,
              opacity: 1,
            }}
          >
            <Grid container>
              <Grid item xs={!up1177 ? 6 : 6}></Grid>
              <Grid item xs={!up1177 ? 6 : 6}>
                <Typography
                  sx={{ backgroundColor: "#fff" }}
                  mt={1}
                  ml={1}
                  pt={2}
                  color="error"
                  align="center"
                >
                  {descriptionError}
                  {buildingStates?.submitDisabled ||
                  buildingStates?.addingNewDesignPro
                    ? "Please save the new design professional after filling all required fields before proceeding."
                    : buildingStates?.submitDisabled ||
                      buildingStates?.addingNewProject
                    ? "Please save the new project contact after filling all required fields before proceeding."
                    : buildingStates?.submitDisabled ||
                      buildingStates?.addingNewFeeSimple
                    ? "Please save the new fee simple title holder after filling all required fields before proceeding."
                    : buildingStates?.submitDisabled ||
                      buildingStates?.addingNewBonding
                    ? "Please save the new bonding company after filling all required fields before proceeding."
                    : buildingStates?.submitDisabled ||
                      buildingStates?.addingNewMortgage
                    ? "Please save the new mortgage lender after filling all required fields before proceeding."
                    : buildingStates?.submitDisabled &&
                      "To continue, you must complete the design professional modifications/additions"}
                </Typography>

                {paisStates?.paisSearching && creatingDoc === "pais" && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      ml: "24px",
                      alignItems: "center",

                      gap: 1,
                      mb: 1,
                    }}
                  >
                    <LoadingButton
                      onClick={(e) => {
                        e.stopPropagation();
                        if (Boolean(paisStates?.editData)) {
                          setPaisStates((prev) => ({
                            ...prev,
                            startDeleteProduct: true,
                          }));
                        } else {
                          setPaisStates((prev) => ({
                            ...prev,
                            resettingData: true,
                          }));
                        }
                      }}
                      color="error"
                      loading={paisStates?.loadingData}
                      disabled={
                        !paisStates?.category || !paisStates?.subCategory
                      }
                      variant="outlined"
                    >
                      {Boolean(paisStates?.editData)
                        ? "Delete Product"
                        : "New Search"}
                    </LoadingButton>
                    <LoadingButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setPaisStates((prev) => ({
                          ...prev,
                          isCreatingPais: true,
                        }));
                      }}
                      color="success"
                      loading={paisStates?.loadingData}
                      disabled={
                        !paisStates?.category || !paisStates?.subCategory
                      }
                      variant="contained"
                    >
                      {Boolean(paisStates?.editData)
                        ? "Edit Product"
                        : "Add Product"}
                    </LoadingButton>
                    {Boolean(paisStates?.editData) && (
                      <LoadingButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setPaisStates((prev) => ({
                            ...prev,
                            resettingData: true,
                          }));
                        }}
                        color="primary"
                        loading={paisStates?.loadingData}
                        disabled={
                          !paisStates?.category || !paisStates?.subCategory
                        }
                        variant="outlined"
                      >
                        Cancel Edit
                      </LoadingButton>
                    )}
                  </Box>
                )}
                <Box
                  sx={{
                    borderTop:
                      paisStates?.paisSearching && creatingDoc === "pais"
                        ? "4px solid #f3f3f3"
                        : undefined,
                    display: "flex",
                    justifyContent: "space-between",
                    ml: "24px",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {/* {serviceTiers?.permit_package !== "Unlimited" &&
                  !selectedRow?.payments?.paid &&
                  !selectedRow?.payments?.invoice_num &&
                  creatingDoc !== "noc" ? (
                    <></>
                  ) :  */}
                  {/* ( */}
                  <LoadingButton
                    onClick={() => handleNavigate("back")}
                    disabled={
                      leftValue === 0 ||
                      createDocLoading ||
                      paisStates?.loadingData ||
                      paisStates?.saveTemplateLoading ||
                      buildingStates?.addingNewDesignPro ||
                      buildingStates?.addingNewProject ||
                      buildingStates?.addingNewFeeSimple ||
                      buildingStates?.addingNewBonding ||
                      buildingStates?.addingNewMortgage
                    }
                    sx={{
                      visibility: leftValue === 0 ? "hidden" : undefined,
                    }}
                    variant="contained"
                    startIcon={<ArrowBackIcon />}
                  >
                    Back
                  </LoadingButton>
                  {/* )} */}
                  {/* {serviceTiers?.permit_package !== "Unlimited" &&
                  !selectedRow?.payments?.paid &&
                  !selectedRow?.payments?.invoice_num &&
                  creatingDoc !== "noc" ? (
                    <></>
                  ) : ( */}
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    {creatingDoc === "noc" && !selectedRow?.noc_created && (
                      <LoadingButton
                        onClick={() =>
                          setNocStates({
                            ...nocStates,
                            previewLoading: true,
                            isNocPreview: true,
                            cardFlip: false,
                          })
                        }
                        disabled={nocStates.createNocLoading}
                        loading={nocStates.previewLoading}
                        variant="contained"
                        color="warning"
                        sx={{
                          mt: 2,
                          mb: isMd ? 1 : 2,
                        }}
                        endIcon={<EditIcon />}
                      >
                        Preview
                      </LoadingButton>
                    )}

                    <Button
                      disabled={
                        createDocLoading ||
                        obtainPermitStates?.permissionLoading ||
                        paisStates?.loadingData ||
                        nocStates.previewLoading ||
                        nocStates.editNocLoading
                      }
                      sx={{ mt: 2, mb: isMd ? 1 : 2, minWidth: "10rem" }}
                      color="primary"
                      variant="outlined"
                      onClick={handleIconButton}
                    >
                      Main Menu
                    </Button>

                    {creatingDoc === "pais" ? (
                      <LoadingButton
                        disabled={
                          createDocLoading ||
                          obtainPermitStates?.permissionLoading ||
                          paisStates?.loadingData ||
                          (creatingDoc === "pais" && !paisStates?.saveIsReady)
                        }
                        endIcon={
                          up1177 && creatingDoc !== "pais" ? (
                            <SendIcon color="#fff" />
                          ) : undefined
                        }
                        loading={
                          paisStates?.saveTemplateLoading || templateLoading
                        }
                        color="warning"
                        // onClick={handleSaveTemplate}
                        onClick={() => {
                          console.log(paisStates?.useTemplate);
                          if (!paisStates?.useTemplate) {
                            handleLoadPaisTemplates(
                              setTemplateLoading,
                              currentSession,
                              selectedRow,
                              setPaisStates,
                              dispatch,
                              true
                            );
                          } else {
                            setPaisStates((prev) => ({
                              ...prev,
                              openModal: true,
                            }));
                          }
                        }}
                        sx={{
                          // visibility:
                          //   creatingDoc === "pais" ? "hidden" : undefined,
                          mt: 2,
                          mb: isMd ? 1 : 2,
                          px: 1,
                          minWidth: "100px",
                        }}
                        variant="contained"
                      >
                        SAVE TEMPLATE
                      </LoadingButton>
                    ) : (
                      <LoadingButton
                        disabled={
                          buildingStates?.submitDisabled ||
                          buildingStates?.addingNewDesignPro ||
                          buildingStates?.addingNewProject ||
                          buildingStates?.addingNewFeeSimple ||
                          buildingStates?.addingNewBonding ||
                          buildingStates?.addingNewMortgage ||
                          nocStates.previewLoading
                        }
                        // endIcon={up1177 ? <SendIcon color="#fff" /> : undefined}
                        loading={
                          createDocLoading ||
                          obtainPermitStates?.permissionLoading ||
                          nocStates.createNocLoading ||
                          nocStates.editNocLoading
                        }
                        color={
                          selectedRow?.documents?.[creatingDoc]?.is_created
                            ? "warning"
                            : "success"
                        }
                        onClick={() => {
                          if (
                            creatingDoc === "noc" &&
                            !selectedRow?.noc_created
                          ) {
                            setNocStates({
                              ...nocStates,
                              createNocLoading: true,
                              isCreatingNoc: true,
                              cardFlip: false,
                            });
                          } else if (
                            creatingDoc === "noc" &&
                            selectedRow?.noc_created
                          ) {
                            setNocStates({
                              ...nocStates,
                              editNocLoading: true,
                              isEditNoc: true,
                            });
                          } else {
                            setPermitFormStates((prev) => ({
                              ...prev,
                              isCreatingForm: true,
                            }));
                          }
                        }}
                        sx={{
                          mt: 2,
                          mb: isMd ? 1 : 2,
                        }}
                        endIcon={
                          selectedRow?.documents?.[creatingDoc]?.is_created ? (
                            <EditIcon />
                          ) : undefined
                        }
                        variant="contained"
                      >
                        {!selectedRow?.selected_documents[leftValue]?.created
                          ? "Create Document"
                          : "Update Document"}
                      </LoadingButton>
                    )}
                    {selectedRow?.selected_documents?.length === 1 &&
                      selectedRow?.noc_created && (
                        <Button
                          sx={{ width: "10rem" }}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setPermitDrawerOpen(false);
                            setRightValue(2);
                          }}
                        >
                          Finish
                        </Button>
                      )}
                    {/* {(currentUser?.tier?.title === "Pay As You Go" ||
                      currentUser?.tier?.title === "Starter") &&
                      selectedRow?.selected_documents[leftValue]?.created &&
                      leftValue === 0 && (
                        <Button
                          onClick={handlePromoDownload}
                          endIcon={<DownloadIcon />}
                          variant="outlined"
                        >
                          Download NOC
                        </Button>
                      )} */}
                  </Box>
                  <LoadingButton
                    sx={{
                      visibility:
                        selectedRow?.selected_documents.length === 1
                          ? "hidden"
                          : undefined,
                      minWidth: "100px",
                    }}
                    color={
                      leftValue === selectedRow?.selected_documents.length - 1
                        ? "success"
                        : "primary"
                    }
                    onClick={() => {
                      if (
                        leftValue ===
                        selectedRow?.selected_documents.length - 1
                      ) {
                        handleIconButton();
                      } else {
                        // if (
                        //   (currentUser?.tier?.title === "Pay As You Go" ||
                        //     currentUser?.tier?.title === "Starter") &&
                        //   selectedRow?.selected_documents[leftValue]
                        //     ?.created &&
                        //   leftValue === 0
                        // ) {
                        //   handlePromoDownload();
                        // }
                        // else {
                        handleNavigate("forward");
                        // }
                      }
                    }}
                    disabled={
                      !selectedRow?.selected_documents[leftValue]?.created ||
                      createDocLoading ||
                      paisStates?.loadingData ||
                      paisStates?.saveTemplateLoading ||
                      buildingStates?.addingNewDesignPro ||
                      buildingStates?.addingNewProject ||
                      buildingStates?.addingNewFeeSimple ||
                      buildingStates?.addingNewBonding ||
                      buildingStates?.addingNewMortgage ||
                      nocStates.previewLoading ||
                      nocStates.editNocLoading
                    }
                    variant="contained"
                    endIcon={
                      leftValue ===
                      selectedRow?.selected_documents.length - 1 ? undefined : (
                        <ArrowForward />
                      )
                    }
                  >
                    {leftValue === selectedRow?.selected_documents.length - 1
                      ? "Finish"
                      : "Next"}
                  </LoadingButton>
                  {/* )} */}
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </Grid>
      </Grid>
      <Footer height="60px" />
      <CityScraperStartModal
        open={cityModalOpen}
        setOpen={setCityModalOpen}
        selectedRow={selectedRow}
        setConsentAlert={setConsentAlert}
        setNocStates={setNocStates}
      />
      <PromoModal
        open={promoModalOpen}
        setOpen={setPromoModalOpen}
        selectedRow={selectedRow}
        currentSession={currentSession}
        setPaymentModalOpen={setPaymentModalOpen}
        currentPopup={currentPopup}
        setCurrentPopup={setCurrentPopup}
        currentUser={currentUser}
        setPermitDrawerOpen={setPermitDrawerOpen}
      />
      <DemoPopPaymentModal
        open={paymentModalOpen}
        setOpen={setPaymentModalOpen}
        paymentCards={paymentCards}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handlePayment={handlePayment}
        paymentLoading={paymentLoading}
        title={
          currentPopup === "pop1"
            ? "Purchase Notarization & Recording Package"
            : currentPopup === "vip"
            ? "Purchase VIP Package"
            : currentPopup === "nocPaymentPop"
            ? "Purchase Notice of Commencement Form"
            : "Purchase Permit Package"
          // nocNotaryPop
          // ppNotaryPop
          // ppPaymentPop
        }
        currentPopup={currentPopup}
        setCurrentPopup={setCurrentPopup}
        currentUser={currentUser}
        setAddPaymentModalOpen={setAddPaymentModalOpen}
      />
      <AddPaymentCardModal
        addPaymentModalOpen={addPaymentModalOpen}
        setAddPaymentModalOpen={setAddPaymentModalOpen}
        stripe={stripe}
        elements={elements}
        CardElement={CardElement}
      />
    </>
  );
};

export default OpenDocuments;
